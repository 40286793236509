const ROMANIC = {
  1: 'I',
  2: 'II',
  3: 'III',
  4: 'IV',
}

export default class Quarter {
  constructor() {
    this.quarterDivider = 'Q'
    this.date = new Date()
    this.year = this.date.getFullYear()
    this.month = this.date.getMonth()
    this.quarter = Math.ceil((this.month + 1) / 3)
    this.defaultRequestParams = {
      year: this.year,
      quarter: this.quarter,
    }
  }

  static asRonamic(quarter) {
    if (!quarter) return ''

    return ROMANIC[quarter] ?? ''
  }

  getReadableQuarterByParams(year, quarter, dative = false) {
    const quarterText = dative ? 'кварталу' : 'квартал'
    const yearText = dative ? 'году' : 'год'
    return `${Quarter.asRonamic(quarter)} ${
      quarter ? quarterText : ''
    } ${year} ${!quarter ? yearText : ''}`.trim()
  }

  getReadableQuarterById(id, dative = false) {
    return this.getReadableQuarterByParams(
      this.getParamsByQuarterId(id).year,
      this.getParamsByQuarterId(id).quarter,
      dative
    )
  }

  getReadableCurrentQuarter(dative = false) {
    return this.getReadableQuarterByParams(this.year, this.quarter, dative)
  }

  getQuarterIdByParams(year, quarter) {
    return `${year}${quarter ? this.quarterDivider : ''}${quarter || ''}`
  }

  getCurrentQuarterId(add = 0) {
    const q = this.quarter + add - 1
    const y = this.year + Math.floor(q / 4)
    const x = (q % 4) + 1
    return this.getQuarterIdByParams(y, x)
  }

  getParamsByQuarterId(quarterId) {
    const q = quarterId?.split(this.quarterDivider)

    return Array.isArray(q) ? { year: q[0], quarter: q[1] } : {}
  }

  getRequestParams(selectedQuarter = '') {
    const data = selectedQuarter.split(this.quarterDivider)

    if (!(data.length === 2 || data.length === 1))
      return this.defaultRequestParams

    const params = {
      year: data[0] ?? this.defaultRequestParams.year,
      quarter: data[1] ?? 0, //this.defaultRequestParams.quarter,
    }

    return { year: +params.year, quarter: +params.quarter }
  }

  isContainsQuarter(selectedQuarter = '') {
    const data = selectedQuarter.split(this.quarterDivider)

    return data.length > 1
  }
  /** Возвращает текущий год, месяц, квартал **{ year, month, quarter }**  */
  getCurrentYearQuarter() {
    const date = new Date()

    // текущий год / квартал
    const year = date.getFullYear()
    const month = date.getMonth()
    const quarter = Math.ceil((month + 1) / 3)

    return { year, month, quarter }
  }

  /** Провееряем есть ли в списке нужный год и квартал */
  checkExist(quartersList, year, quarter) {
    return (
      !!quartersList?.find(
        ql => ql.year === year && (!quarter || ql.quarter === quarter)
      ) ?? true
    )
  }

  /** считаем кол-во есть ли в списке нужный год и квартал */
  calcCount(quartersList, year, quarter) {
    const arr =
      quartersList?.filter(
        ql => ql.year === year && (!quarter || ql.quarter === quarter)
      ) ?? []
    return arr.reduce((sum, { count }) => sum + count, 0)
  }

  getQuarterAdditional(quartersList, year, quarter) {
    return {
      readableQuarter: this.getReadableQuarterByParams(year, quarter),
      quarterId: this.getQuarterIdByParams(year, quarter),
      exist: this.checkExist(quartersList, year, quarter),
      count: this.calcCount(quartersList, year, quarter),
    }
  }
}
