<template>
  <div>
    <!-- заголовок из роутера -->
    <PageTitle :title="title" icon small class="text-h6" />
    <v-progress-linear v-if="loading" indeterminate />

    <!-- затенение и крутилка на загрузку -->
    <v-overlay :value="loadingVersionList" light class="text-center">
      <span v-if="loadingPlpData">Получение данных PLP...</span>
    </v-overlay>

    <!-- АЛЕРТ если нет назначения для экспертизы (экспертизы) -->
    <div v-if="!loadingPlp && is_expertise && !plp_task_id" class="ma-5">
      <v-alert border="bottom" colored-border type="warning" elevation="2">
        Для Вас нет назначений для экспертизы этого препарата
      </v-alert>
    </div>

    <!-- АЛЕРТ если нет назначения для сравнения (кураторы) -->
    <div v-if="!loadingPlp && is_compare && !canCompare" class="ma-5">
      <v-alert border="bottom" colored-border type="warning" elevation="2">
        У Вас нет прав для выполнения сравнения версий
      </v-alert>
    </div>

    <!-- просмотр plp -->
    <MedicinePreview v-if="is_preview && !loading" />

    <!-- роутер для содержимого экспертиз - появится только после загрузки plp -->
    <router-view
      v-if="!is_preview && !loadingPlp && is_expertise_view"
      :class="{ is_expertise: 'wd-70' }"
    />
  </div>
</template>

<style scoped>
@media (min-width: 960px) {
  .wd-70 {
    width: 95%;
  }
}
@media (min-width: 1264px) {
  .wd-70 {
    width: 80%;
  }
}
</style>

<script>
import PageTitle from '@/components/PageTitle.vue'
import PathWorker from '@/lib/path'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import MedicinePreview from '@/views/MedicinePreview'

export default {
  name: 'Medicine-View',
  components: { PageTitle, MedicinePreview },
  data: () => ({
    showPreview: false,
    comparePage: false,
    title: '',
  }),
  created() {
    this.init(true)
    this.getTitle()
  },
  destroyed() {
    this.SET_DEFAULT()
    this.COMPARE_CLEAR()
  },
  methods: {
    ...mapActions(['GET_PLP', 'SET_CURRENT_EXP', 'COMPARE_GET_EXPERTISE']),
    ...mapMutations(['SET_DEFAULT', 'COMPARE_CLEAR']),
    async init(first) {
      if (first) {
        const plpId = new PathWorker(this.$route).getMedicineId
        await this.GET_PLP(plpId)
      }
      const { ex_type, ex_type_sub, previewPage, comparePage, role } =
        this.$route.meta

      this.showPreview = previewPage ?? false

      this.comparePage = comparePage ?? false
      this.canCompare = role?.includes(this.userRole) ?? false

      // если экспертиза переключаемся
      if (this.is_expertise)
        await this.SET_CURRENT_EXP({ ex_type, ex_type_sub })
      // если сравнение переключаемся
      if (this.is_compare)
        await this.COMPARE_GET_EXPERTISE({ ex_type, ex_type_sub })
    },
    changeRoute() {
      this.init(false)
    },
    getTitle() {
      const { title, caption } = this.$route.meta
      this.title = caption

      if (typeof title === 'string') this.title = title ?? caption
      if (typeof title === 'function') {
        this.title = title() ?? caption
      }
    },
  },
  computed: {
    ...mapGetters([
      'userRole',
      'loadingPlp',
      'loadingPlpData',
      'loadingVersionList',
      'plp_task_id',
      'storedPlp',
    ]),
    is_expertise() {
      const { ex_type, ex_type_sub, comparePage } = this.$route.meta
      return ex_type && ex_type_sub && !comparePage
    },
    is_compare() {
      return !this.is_expertise && this.comparePage
    },
    is_preview() {
      return this.showPreview
    },
    is_expertise_view() {
      return (
        (this.is_expertise && this.plp_task_id) ||
        (this.is_compare && this.canCompare) ||
        !this.is_expertise
      )
    },
    loading() {
      return this.loadingVersionList || this.loadingPlpData
    },
  },
  watch: {
    // при изменениях маршрута запрашиваем данные снова
    '$route.params': 'changeRoute',
    '$route.meta.caption': 'getTitle',
    storedPlp: 'getTitle',
  },
}
</script>
