export function customSearchField(value, search, fields = null) {
  if (value === null || search === null) return false

  let res = null

  if (typeof value === 'object') {
    let obj = { ...value }
    if (fields !== null && obj[fields.field] === null) {
      obj[fields.field] = fields.value
    }
    let elements = Object.values(obj)
    res = elements.reduce(
      (sum, el) => sum || isIncludesSearch(el, search),
      false
    )
  }

  if (res) return true

  if (typeof value === 'number') {
    value = value.toString()
  }

  return isIncludesSearch(value, search)
}

function isIncludesSearch(val, search) {
  if (typeof val === 'string') {
    return val
      .toString()
      .toLocaleLowerCase()
      .includes(search.toLocaleLowerCase())
  }

  if (Array.isArray(val) && val.length) {
    return val.reduce((sum, el) => sum || customSearchField(el, search), false)
  }

  return false
}
