<template>
  <ActionDialog
    v-bind="$attrs"
    v-on="$listeners"
    label="Вы действительно хотите удалить лекарственный препарат ?"
    color-btn-action="error"
    title="ВНИМАНИЕ!!!"
    labelBtnClose="ОТМЕНА"
    labelBtnAction="УДАЛИТЬ"
  />
</template>

<script>
import ActionDialog from '@/components/dialogs/ActionDialog.vue'
export default {
  name: 'WorkDistributionDeleteDialog',
  components: { ActionDialog },
}
</script>
