<template>
  <v-container :class="{ 'rounded-xl teal lighten-5 elevation-3': canEdit }">
    <v-row dense class="caption font-weight-light text-right">
      <v-col cols="6" class="text-h6 font-weight-light text-left">
        Отчётный период: <b>{{ storedPlpYearQuarter }}</b></v-col
      >
      <v-col cols="6"
        ><span>Дата изменения: {{ updateTime }}</span></v-col
      >
    </v-row>
    <v-row v-if="isManager" dense class="px-3">
      <v-spacer />
      <v-col cols="2" class="d-flex justify-end">
        <v-switch
          v-model="editMode"
          label="Редактировать"
          hide-details
          class="my-0"
        />
      </v-col>
    </v-row>
    <PlpDataForm
      v-if="storedPlpDataReady"
      :value="storedPlpData"
      :path="storedPlpPath"
      :company="storedPlpCompany"
      :companies="companiesList"
      :companiesLoading="companiesLoading"
      :readonly="!canEdit"
      @submit="onSave"
    >
      <template #footer="{ data, company, readonly }">
        <v-row v-if="!readonly && isManager" dense>
          <v-col class="text-right">
            <v-btn
              color="success"
              @click="onSave(data, company)"
              :loading="saving"
              ><v-icon left>mdi-content-save</v-icon>Сохранить
            </v-btn></v-col
          ></v-row
        ></template
      >
    </PlpDataForm>
    <!-- Успешно сохранились -->
    <v-alert v-if="saveMessage" type="success" outlined dismissible>
      {{ saveMessage }}
    </v-alert>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PlpDataForm from '@/components/plp/PlpDataForm'

export default {
  name: 'Medicine-Preview',
  components: { PlpDataForm },
  data: () => ({
    editMode: false,
    saving: false,
    saveMessage: '',
    companiesLoading: false,
    companyItem: [],
    companyModel: '',
    companyId: null,
    dialogCompanyBind: false,
  }),
  watch: {
    async editMode(val) {
      if (!val || this.companiesLoading) return
      this.companiesLoading = true
      try {
        await this.get_companies()
      } finally {
        this.companiesLoading = false
      }
    },
  },
  computed: {
    ...mapGetters([
      'storedPlp',
      'storedPlpData',
      'storedPlpDataReady',
      'storedPlpPath',
      'storedPlpMNN',
      'storedPlpYearQuarter',
      'storedPlpCompany',
      'companiesList',
      'isManager',
    ]),
    canEdit() {
      return this.isManager && this.editMode
    },
    // createTime() {
    //   if (this.storedPlp?.time_create) {
    //     return new Date(this.storedPlp.time_create).toLocaleString()
    //   } else return ''
    // },
    updateTime() {
      if (this.storedPlp?.time_edit) {
        return new Date(this.storedPlp.time_edit).toLocaleString()
      } else return ''
    },
  },
  created() {
    this.GET_PLP_DATA()
  },

  methods: {
    ...mapActions(['GET_PLP_DATA', 'UPDATE_PLP', 'get_companies']),
    async onSave(data, company) {
      this.saveMessage = ''
      this.saving = true
      try {
        const company_id = company?.id ?? null
        await this.UPDATE_PLP(
          data ? { data, company_id, company } : { company_id, company }
        )
        this.editMode = false
        this.saveMessage = 'Сохранение выполнено успешно!'
      } finally {
        this.saving = false
      }
    },
  },
}
</script>
