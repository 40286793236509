<template>
  <div id="filter-view">
    <v-row v-if="title">
      <v-col :class="titleClass"> {{ title }}</v-col>
    </v-row>
    <v-row no-gutters v-if="Object.keys(dataItems).length">
      <v-col v-for="(value, key) in dataItems" :key="key" md="3">
        <v-switch
          v-if="filterType === 'switch'"
          v-model="selected"
          :value="key"
          @change="send()"
        >
          <template v-slot:label>
            <div
              class="body-2"
              :class="`${
                selected.includes(key) ? 'text--primary' : 'text--disabled'
              }`"
            >
              {{ value }}
            </div>
          </template>
        </v-switch>
        <v-checkbox
          v-else-if="filterType === 'checkbox'"
          v-model="selected"
          :value="key"
          hide-details
          class="ma-0 pa-0 mb-2"
          @change="send()"
        >
          <template v-slot:label>
            <div
              class="body-2"
              :class="`${
                selected.includes(key) ? 'text--primary' : 'text--disabled'
              }`"
            >
              {{ value }}
            </div>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'FilterView',
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    titleClass: {
      type: String,
      required: false,
      default: '',
    },
    filterType: {
      type: String,
      required: true,
      default: 'checkbox',
    },
    dataItems: {
      type: Object,
      required: true,
      default: () => {},
    },
    defaultState: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      selected: [],
    }
  },
  created() {
    this.selected = this.defaultState
  },
  watch: {
    defaultState(val) {
      this.selected = val
    },
  },
  methods: {
    send() {
      this.$emit('input', this.selected)
    },
    remove() {
      this.selected = []
    },
  },
}
</script>
