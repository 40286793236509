<template>
  <div
    id="filter-alert"
    class="d-flex justify-end align-end"
    :class="{ 'pa-2': !dense }"
  >
    <v-alert
      text
      :color="isFiltered ? 'success' : 'info'"
      class="mb-0 pa-2 px-2 caption"
    >
      {{ isFiltered ? msgFilterInUse : msgFilterNotUse }}
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'FilterAlert',
  props: {
    msgFilterInUse: {
      type: String,
      required: false,
      default: 'Внимание! Работает фильтр',
    },
    msgFilterNotUse: {
      type: String,
      required: false,
      default: 'Фильтр не используется',
    },
    isFiltered: {
      type: Boolean,
      require: true,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
