<template>
  <v-progress-linear v-if="loading" color="secondary" indeterminate rounded />

  <v-alert v-else-if="!deadlines.length" type="error" outlined class="mb-0">
    Для вас отсутствуют сроки выполнения, т.к вы не назначены ни на одну
    экспертизу
  </v-alert>

  <DeadlinesList
    v-else
    :data="deadlines"
    @change="changeDeadlines"
    :readonly="!isManager"
  />
</template>

<script>
import { mapActions } from 'vuex'
import { mapGetters } from 'vuex'
import DateForPicker from '@/lib/datetime'
import DeadlinesList from './DeadlinesList.vue'
import { copyObject } from '@/lib/objects'

export default {
  name: 'deadlines-dates',
  components: { DeadlinesList },
  data: () => ({
    loading: true,
    error: '',
    deadlines: [],
  }),
  async created() {
    this.loading = true
    try {
      const deadlineTypes = await this.GET_DEADLINE_TYPES()
      this.deadlines = this.collectDts(this.storedPlpDeadlines, deadlineTypes)
    } catch (err) {
      this.error = `${err.message} ${err.error}`.trimEnd()
      throw err
    } finally {
      this.loading = false
    }
  },

  computed: {
    ...mapGetters(['isManager', 'storedPlpDeadlines', 'storedMyTasks']),
  },

  methods: {
    ...mapActions(['GET_DEADLINE_TYPES']),
    getDatePicker(date) {
      return date ? new DateForPicker(date).toISO8601date : null
    },
    collectDts(dts, deadlineTypes) {
      const outArr = []
      const initDts = copyObject(dts)

      deadlineTypes.forEach(item => {
        const findItem = initDts.find(
          elem => item.name == elem.deadlineType.name
        )
        if (findItem) {
          outArr.push({
            first_date: this.getDatePicker(findItem.first_date),
            last_date: this.getDatePicker(findItem.last_date),
            deadlineType: findItem.deadlineType,
          })
        } else {
          outArr.push({
            first_date: null,
            last_date: null,
            deadlineType: item,
          })
        }
      })

      const myExpertiseTypes = this.storedMyTasks.map(
        item => item.expertiseType?.name
      )

      //Отфильтровываем все сроки для данного исполнителя
      return this.isManager
        ? outArr
        : outArr.filter(item =>
            item.deadlineType?.data?.expertise_types?.some(d =>
              myExpertiseTypes.includes(d)
            )
          )
    },
    changeDeadlines(value) {
      this.$emit(
        'change',
        value.map(item => ({
          deadline_type_id: item.deadlineType.id,
          first_date: item.first_date,
          last_date: item.last_date,
        }))
      )
    },
  },
}
</script>
