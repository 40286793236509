<template>
  <div
    class="display-1 mb-0"
    style="font-family: 'ErbaumBook', sans-serif !important"
  >
    <div v-if="title">
      {{ title }}
      <v-icon v-if="icon" :large="!small" right v-text="mdiIcon" />
    </div>
    <slot v-else />
  </div>
</template>

<script>
export default {
  name: 'Page-Title',
  props: {
    title: {
      type: String,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mdiIcon() {
      return this.$route.meta?.icon
    },
  },
}
</script>
