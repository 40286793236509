<template>
  <ActionDialog
    v-bind="$attrs"
    v-on="$listeners"
    label="Вы действительно хотите закрыть проект и отправить его в Минздрав ?"
    labelBtnClose="ОТМЕНА"
    labelBtnAction="ОТПРАВИТЬ"
  />
</template>

<script>
import ActionDialog from '@/components/dialogs/ActionDialog.vue'
export default {
  name: 'MedicinePAgeSendDialog',
  components: { ActionDialog },
}
</script>
