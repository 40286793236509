<template>
  <v-autocomplete
    :auto-select-first="autoSelectFirst"
    v-model="model"
    :items="items"
    :item-value="itemValue"
    :item-text="itemText"
    :label="label"
    @change="onChangeHook"
    :error="error"
    :error-messages="errorMessages"
    :loading="loading"
    :disabled="disabled"
    :dense="dense"
  >
    <template #prepend><slot name="icon" /></template>
    <template #append-outer><slot name="status" /></template>
    <!-- return-object-->
    <template #item="user">
      <v-list-item ripple two-line>
        <v-list-item-content>
          <v-list-item-title>
            <span :class="getItemColorClass(user.item)">
              {{ user.item.fio | shortFIO }}
            </span>
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ user.item.position }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-icon>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                :color="user.item.color ? 'error' : 'secondary'"
                dark
                v-bind="attrs"
                v-on="on"
              >
                {{ getIcon(user.item) }}
              </v-icon>
            </template>
            <span>
              {{ (user.item.role && user.item.role.title) || '' }}
            </span>
          </v-tooltip>
        </v-list-item-icon>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'AutocompleteChoiceUser',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    itemValue: {
      type: String,
      required: false,
      default: 'id',
    },
    itemText: {
      type: String,
      required: false,
      default: 'fio',
    },
    autoSelectFirst: {
      type: Boolean,
      required: false,
      default: true,
    },
    dense: {
      type: Boolean,
      required: false,
      default: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
    errorMessages: {
      type: String,
      required: false,
      default: '',
    },
    onChange: {
      type: Function,
      required: false,
      default: null,
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },

  methods: {
    onChangeHook(v) {
      // console.log('log', this.items[this.value][this.itemValue])
      this.$emit('change', v)
      if (this.onChange) this.onChange()
    },

    getIcon(item) {
      let icon = ''
      if (!item.role) return ''

      // eslint-disable-next-line no-prototype-builtins
      if (!item.role.hasOwnProperty('id')) return ''

      switch (item.role.id) {
        case 1:
          icon = 'mdi-account-star-outline'
          break
        case 2:
          icon = 'mdi-emoticon-cool-outline'
          break
        case 3:
          icon = 'mdi-motorbike'
          break
        case 4:
          icon = 'mdi-account-outline'
          break
        default:
          icon = 'mdi-account'
          break
      }

      return icon
    },

    getItemColorClass(item) {
      // TODO SWITCH CASE
      if (item.color) {
        if (item.color === 'red') {
          return 'test-color'
        }
      }

      return ''
    },
  },
}
</script>

<style scoped>
.test-color {
  color: red !important;
}
</style>
