export function debounce(func, wait, immediate) {
  let timeout

  return function executedFunction() {
    const context = this
    const args = arguments
    const later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }

    const callNow = immediate && !timeout
    clearTimeout(timeout)

    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export function getParticipant(item = null, userId = null, expTypes = []) {
  if (userId === null || item === null) return false

  let res = {}
  res.contains = expTypes.reduce(
    (acc, type) =>
      acc || item[type.name].filter(el => el.id === userId).length > 0,
    false
  )

  res.completed = expTypes.reduce(
    (acc, type) =>
      acc &&
      item[type.name]
        .filter(el => el.id === userId)
        .reduce((a, b) => a && b.is_completed, true),
    true
  )

  return res
}
