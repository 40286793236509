<template>
  <v-dialog v-model="dialogState" max-width="80%" persistent>
    <v-card v-if="dialog">
      <v-card-title>
        Изменить настройки (эксперты / сроки / подписант)
      </v-card-title>
      <v-card-subtitle>
        <p>{{ medicine.mnn || medicine.name }}</p>
      </v-card-subtitle>
      <v-card-text>
        <MedicineSettingsView
          :medicine="medicine"
          @onChange="settings => changeItem(settings)"
          @onHasError="val => setHasError(val)"
        />
        <!-- :selectedItem="selectedItem" -->
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" text :disabled="hasError" @click="closeAndSave">
          СОХРАНИТЬ
        </v-btn>
        <v-btn color="primary" text @click="closeDialog"> ЗАКРЫТЬ </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import MedicineSettingsView from '@/components/views/MedicineSettingsView.vue'

export default {
  name: 'WorkDistributionEditDialog',
  components: { MedicineSettingsView },
  data: () => ({
    changes: {
      tasks: [],
    },
    hasError: false,
    dialogState: false,
  }),
  props: {
    // selectedItem: {
    //   type: Object,
    //   required: true,
    //   default: () => ({
    //     name: 'DEFAULT',
    //     tasks: [],
    //   }),
    // },
    medicine: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  watch: {
    dialog(val) {
      this.dialogState = val
    },
  },

  methods: {
    ...mapActions(['SAVE_SETUP']),

    changeItem(settings) {
      this.changes = settings
    },

    setHasError(val) {
      this.hasError = val
    },

    async closeAndSave() {
      if (Object.keys(this.changes).length > 0) {
        // this.changes.docId = this.selectedItem.id
        this.changes.docId = this.medicine.id
        await this.SAVE_SETUP(this.changes)
        this.$toast.success('Сохранено')
      }
      this.closeDialog()
    },

    closeDialog() {
      this.changes = {
        tasks: [],
      }
      // this.dialog = false
      this.$emit('onClose')
    },
  },
}
</script>
