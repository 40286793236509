var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"report-user-assigments"}},[_c('MultiFilter',{ref:"multiFilter",attrs:{"filterActiveGroup":_vm.active,"filtersData":_vm.FILTER_USERS_ASSIGNED},on:{"apply":_vm.filterApply}}),_c('v-row',{staticClass:"my-1"},[_c('v-col',{staticClass:"pt-1"},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-data-table',{attrs:{"dense":"","items":_vm.dataReports,"headers":_vm.headers,"search":_vm.search,"no-data-text":_vm.isNotAllowedQuarter
              ? 'Отчёт за весь период не предоставляется'
              : 'Нет назначенных работ за этот квартал',"no-results-text":"Ничего не нашлось","hide-default-footer":"","disable-pagination":"","custom-filter":_vm.customFilterField,"loading":!_vm.docReady},on:{"pagination":_vm.pagination},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('FilterAlert',{attrs:{"isFiltered":_vm.isFiltered}})]},proxy:true},{key:"item.index",fn:function(ref){
              var index = ref.index;
return [_c('span',{staticClass:"cursor-pointer"},[_vm._v(_vm._s(_vm.pageStart + index + 1)+".")])]}},_vm._l((_vm.expTypes),function(valType){return {key:"item."+valType.name,fn:function(ref){
              var value = ref.value;
return [_vm._v(" "+_vm._s(value || 0)+" ")]}}}),{key:"item.user",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(_vm._f("shortFIO")(item.user.fio))+" ")]),_c('div',{staticClass:"text--secondary caption"},[_vm._v(" "+_vm._s(item.user.position || _vm.EMPTY_POSITION_TXT)+" ")])]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',[_c('td'),_c('td',{staticClass:"font-weight-bold"},[_vm._v("Всего назначено:")]),_vm._l((_vm.expTypes),function(valType,index){return _c('td',{key:index,staticClass:"text-xs-right font-weight-bold"},[_c('v-layout',{attrs:{"justify-center":""}},[_vm._v(" "+_vm._s(_vm.getTotals[valType.name] || '0')+" ")])],1)})],2)])],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }