var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"work-distribution-form"}},[_c('MultiFilter',{ref:"multiFilter",attrs:{"filterActiveGroup":_vm.active,"filtersData":_vm.FILTER_WORK_DISTRIBUTION_PAGE},on:{"apply":_vm.filterApply}}),_c('v-data-table',{attrs:{"items":_vm.preparedMedicines,"headers":_vm.headers,"search":_vm.search,"no-data-text":"Нет препаратов за этот квартал","no-results-text":"Ничего не нашлось","custom-filter":_vm.customFilterField,"hide-default-footer":_vm.selectedQuarter !== 'all',"disable-pagination":_vm.selectedQuarter !== 'all',"loading":!_vm.docReady,"items-per-page":50,"footerProps":_vm.table_footer_options},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('FilterAlert',{attrs:{"isFiltered":_vm.isFiltered}})]},proxy:true},_vm._l((_vm.DFD.headers),function(valType){return {key:"header."+valType.name,fn:function(ref){
var header = ref.header;
return [_c('span',{key:valType.name},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dense":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(header.icon)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(header.text))])])],1)]}}}),{key:"header.quarter",fn:function(ref){
var header = ref.header;
return [_c('span',[(_vm.selectedQuarter === _vm.constAll)?_c('span',[_vm._v(" "+_vm._s(header.text)+" ")]):_c('v-icon',{attrs:{"dense":"","title":header.text}},[_vm._v(" "+_vm._s(header.icon)+" ")])],1)]}},{key:"item.index",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('v-badge',{attrs:{"value":item.isParticipant && !item.was_sent_to_mz,"bordered":"","offset-x":"0","offset-y":"0","dot":"","color":((item.isPartCompleted ? 'green' : 'blue') + " lighten-2"),"title":item.isParticipant && !item.was_sent_to_mz
            ? 'Вы являетесь участником экспертизы'
            : ''}},[_c('span',[_vm._v(_vm._s(index + 1)+".")])])]}},{key:"item.quarter",fn:function(ref){
            var value = ref.value;
            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.selectedQuarter === _vm.constAll ? item.year + ' /' : '')+" "),_c('v-icon',{attrs:{"disabled":""}},[_vm._v(" "+_vm._s(_vm.quarterToIcon(value))+" ")])],1)]}},_vm._l((_vm.columns),function(valType){return {key:"item."+valType.name,fn:function(ref){
            var value = ref.value;
            var item = ref.item;
            var header = ref.header;
return [_c('td',{key:valType.name,class:((header.cellClass || '') + " " + (_vm.getTDClass(item, valType.type))),attrs:{"width":header.width},on:{"click":function($event){valType.type === 'name' && _vm.onRowClick(item)}}},[(valType.type === 'name')?_c('v-speed-dial',{attrs:{"direction":"right","open-on-hover":"","transition":"slide-x-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('div',{staticClass:"text-truncate"},[_c('span',{class:{
                  'font-weight-bold':
                    item.isParticipant && !item.was_sent_to_mz,
                },attrs:{"title":value.name},domProps:{"innerHTML":_vm._f("highlight")(value.name,_vm.search)}}),_c('br'),_c('span',{staticClass:"text--secondary caption"},[_c('span',{domProps:{"innerHTML":_vm._f("highlight")(value.ath || _vm.DEFAULT_EMPTY_ATH_TXT,_vm.search)}}),_vm._v(": "),_c('span',{attrs:{"title":value.lForma},domProps:{"innerHTML":_vm._f("highlight")(value.lForma || _vm.DEFAULT_EMPTY_LF_TXT,_vm.search)}})])])]},proxy:true}],null,true)},[(_vm.isManager && item.was_sent_to_mz)?_c('v-btn',{attrs:{"fab":"","dark":"","x-small":"","depressed":"","color":"orange lighten-2","title":"Отмена отправки в МЗ"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openForUnSend(item)}}},[_c('v-icon',[_vm._v("mdi-backup-restore")])],1):_vm._e(),(_vm.isManager && item.canSendToMZ)?_c('v-btn',{attrs:{"fab":"","dark":"","x-small":"","depressed":"","color":"blue lighten-2","title":"Отправить в МЗ"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openForSend(item)}}},[_c('v-icon',[_vm._v("mdi-cube-send")])],1):_vm._e(),(_vm.isManager || _vm.isSuperAdmin)?_c('v-btn',{attrs:{"fab":"","dark":"","x-small":"","depressed":"","color":"green lighten-2","title":"Редактировать"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openForEdit(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),(_vm.isSuperAdmin)?_c('v-btn',{attrs:{"fab":"","dark":"","x-small":"","depressed":"","color":"red lighten-2","title":"Удалить"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openForDelete(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()],1):_vm._e(),(valType.type === 'status')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text-center",attrs:{"dense":""}},'span',attrs,false),on),[(value)?_c('v-icon',{attrs:{"small":"","color":value ? 'success' : ''}},[_vm._v(" mdi-check ")]):(valType.rule && valType.rule(item))?_c('span',[_c('v-icon',{attrs:{"color":"blue lighten-2","dense":""}},[_vm._v(" mdi-package-variant ")])],1):_c('span',[_vm._v(" - ")])],1)]}}],null,true)},[(valType.rule && valType.rule(item))?_c('span',[_vm._v(" Готово к отправке в Минздрав ")]):_c('span',[_vm._v(_vm._s(value ? 'Да' : 'Нет'))])]):_vm._e(),(valType.type === 'work')?_c('ol',{key:valType.name,class:value.length ? '' : 'ol-padding'},[(value.length === 0)?_c('li',{staticClass:"grey--text",staticStyle:{"list-style":"none"}},[_c('span',{staticClass:"d-flex justify-space-between"},[_c('span',{staticClass:"text-truncate"},[_c('em',[_vm._v(_vm._s(_vm.DEFAULT_NO_POSITION_TXT))])]),_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"grey"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.DFD.unset.icon)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.DFD.unset.title))])])],1)])]):_vm._l((value),function(user,index){return _c('li',{key:index},[(user.fio)?_c('span',{staticClass:"d-flex justify-space-between"},[_c('span',{staticClass:"text-truncate",attrs:{"title":user.fio},domProps:{"innerHTML":_vm._f("highlight")(_vm._f("shortFIO")(user.fio),_vm.search)}}),_c('span',{staticClass:"pl-1"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":_vm.getUserColor(user)}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.getUserAttrs(user).icon)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getUserAttrs(user).title))])])],1)]):_c('span',{staticClass:"d-flex justify-space-between teal--text font-italic"},[_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(_vm.DEFAULT_EMPTY_POSITION_TXT))]),_c('span',{staticClass:"pl-1"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"grey"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.DFD.na.icon)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.DFD.na.title))])])],1)])])})],2):_vm._e(),(valType.type === 'signer')?_c('span',[(item.signer)?_c('span',[_vm._v(" "+_vm._s(_vm._f("highlight")(_vm._f("shortFIO")(item.signer.fio),_vm.search))+" ")]):_c('span',{staticClass:"grey--text font-italic"},[_vm._v(_vm._s(_vm.DEFAULT_NO_POSITION_TXT))])]):_vm._e()],1)]}}})],null,true)}),_c('WorkDistributionEditDialog',{attrs:{"selectedItem":_vm.selectedItem,"medicine":_vm.medicine,"dialog":_vm.dialogEdit},on:{"onClose":_vm.onCloseEditDialog}}),_c('WorkDistributionDeleteDialog',{attrs:{"name":_vm.selectedItem.name,"dialog":_vm.dialogDelete},on:{"onClose":_vm.onCloseDeleteDialog,"onAction":_vm.onDeleteItem}}),_c('MedicinePageSendDialog',{attrs:{"name":_vm.selectedItem.name,"dialog":_vm.dialogSend},on:{"onClose":_vm.onCloseSendDialog,"onAction":_vm.onSendItem}}),_c('MedicinePageUnSendDialog',{attrs:{"name":_vm.selectedItem.name,"dialog":_vm.dialogUnSend},on:{"onClose":_vm.onCloseUnSendDialog,"onAction":_vm.onUnSendItem}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }