<template>
  <div>
    <PageTitle title="Лекарственные препараты" icon />
    <v-container id="work-distribution" fluid>
      <v-row>
        <v-col cols="5">
          <v-select
            label="Период"
            v-model="selectedQuarter"
            :items="preparedQuarters"
            item-text="readableQuarter"
            item-value="quarterId"
            hide-details
            @change="changeQuarter"
          >
            <template #item="{ item, on, attrs }"
              ><v-list-item
                v-on="on"
                v-bind="attrs"
                :class="{
                  [`text--disabled`]: !item.exist,
                  [`font-weight-bold`]: !item.quarter,
                }"
              >
                <v-list-item-title>
                  {{ item.readableQuarter }}
                </v-list-item-title>
                <v-list-item-action-text
                  v-if="item.count"
                  class="text--disabled text-caption"
                  :class="{
                    [`font-weight-bold`]: !item.quarter,
                  }"
                  >{{ item.count }}</v-list-item-action-text
                ></v-list-item
              ></template
            >
          </v-select>
        </v-col>
        <v-col cols="5">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="searchLabel"
            single-line
            hide-details
            class="subtitle-1"
          />
        </v-col>
        <v-col cols="2" class="filter-btn text-end">
          <FilterWidget :active="getActiveTab()" />
        </v-col>
      </v-row>

      <v-row v-if="storeExpTypes.length && tabItems.length">
        <v-col>
          <v-tabs v-model="tab" @change="changeTab">
            <v-tab v-for="(item, index) in tabItems" :key="item.tab">
              <a
                :href="item.path"
                class="nolink"
                @click.stop.prevent="onClickLink(index)"
              >
                {{ item.name }}
              </a>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="item in tabItems" :key="item.tab">
              <component
                v-bind:is="item.content"
                :expTypes="storeExpTypes"
                :selectedQuarter="selectedQuarter"
                :preparedQuarters="preparedQuarters"
                :searchLabel="searchLabel"
                :search="search"
                :filterActiveGroup="item.tab"
                :loading="docReady"
                :activePage="getActiveTab()"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue'
import Quarter from '@/lib/quarter'
import FilterWidget from '@/components/filters/components/FilterWidget.vue'
import { mapActions, mapGetters } from 'vuex'
import TransitionExpand from '@/components/transition/TransitionExpand.vue'
import WorkDistributionForm from '@/components/workdistribution/WorkDistributionForm.vue'
import UserAssigments from '@/components/workdistribution/UserAssigments.vue'
import MedicinesPage from '@/components/workdistribution/MedicinesPage.vue'

const ALL = 'all'
const cStartYear = 2021

export default {
  components: {
    PageTitle,
    FilterWidget,
    TransitionExpand,
    WorkDistributionForm,
    UserAssigments,
    MedicinesPage,
  },
  data: () => ({
    selectedQuarter: ALL,
    quarter: new Quarter(),
    docReady: false,
    search: '',
    tab: null,
    tabs: [
      {
        tab: 'MedicinesPage',
        name: 'Список ЛП',
        content: 'MedicinesPage',
        path: '/medicinepage',
      },
      {
        tab: 'WorkDistributionForm',
        name: 'Распределение',
        content: 'WorkDistributionForm',
        path: '/workdistribution',
      },
      {
        // @TODO Связать с компонентом и настройками фильтра (storage - const)
        tab: 'UserAssigments',
        name: 'Сводный отчёт',
        content: 'UserAssigments',
        path: '/userassigment',
        admin: true,
      },
    ],
    tabItems: [],
  }),

  created() {
    const _this = this
    this.get_choosed_quarter().then(() => {
      // const quarterId = _this.choosedQuarter || 1
      // _this.selectedQuarter = _this.quarter.getCurrentQuarterId(quarterId)
      _this.selectedQuarter = _this.choosedQuarter
      _this.docReady = true
    })

    this.tabs.forEach(tab => {
      // eslint-disable-next-line no-prototype-builtins
      if (tab.hasOwnProperty('admin')) {
        if (tab.admin === this.isManager) {
          this.tabItems.push(tab)
        }
      } else {
        this.tabItems.push(tab)
      }
    })
  },

  mounted() {
    this.get_users()
    const path = this.$router.currentRoute.fullPath
    const index = this.tabs.findIndex(tab => tab.path === path)
    if (index !== -1) {
      this.tab = index
    }
  },

  methods: {
    ...mapActions(['get_users', 'get_choosed_quarter', 'save_choosed_quarter']),
    addQuarters(initQuarters) {
      // стартовый год
      const startYear = initQuarters?.[0]?.year || cStartYear
      const list = []

      let { year, quarter } = this.quarter.getCurrentYearQuarter()

      // если уже 4 квартал добавим следующий год 1-квартал
      if (quarter === 4) {
        list.push({ year: year + 1 })
        list.push({ year: year + 1, quarter: 1 })
      }
      // текущий год
      list.push({ year })
      list.push({ year, quarter: 4 })
      list.push({ year, quarter: 3 })
      list.push({ year, quarter: 2 })
      list.push({ year, quarter: 1 })
      // прошлый год
      if (--year >= startYear) {
        list.push({ year })
        list.push({ year, quarter: 4 })
        list.push({ year, quarter: 3 })
        list.push({ year, quarter: 2 })
        list.push({ year, quarter: 1 })
        // далее просто годами до стартового - архив
        while (year > startYear) {
          list.push({ year: --year })
        }
      }

      return list.map(o => ({
        ...o,
        ...this.quarter.getQuarterAdditional(initQuarters, o.year, o.quarter),
      }))
    },
    getActiveTab() {
      return this.tabItems[this.tab]?.tab || null
    },

    changeQuarter(item) {
      this.save_choosed_quarter(item)
    },

    changeTab(index = -1) {
      if (index !== -1) {
        window.history.pushState(
          'string',
          this.tabs[index]?.name || '',
          window.location.origin + this.tabs[index]?.path || ''
        )
      }
    },

    onClickLink(index) {
      this.tab = index
      this.changeTab(index)
    },
  },

  computed: {
    ...mapGetters([
      'storeQuarters',
      'storeExpTypes',
      'choosedQuarter',
      'isManager',
    ]),

    preparedQuarters() {
      const quarters = this.addQuarters(this.storeQuarters)
      quarters.unshift({
        readableQuarter: 'Выбрать все',
        quarterId: ALL,
        exist: true,
      })
      return quarters
    },
    searchLabel() {
      return this.selectedQuarter === ALL
        ? 'Поиск по всем кварталам'
        : 'Поиск по ' +
            this.quarter.getReadableQuarterById(this.selectedQuarter, 'dative')
    },
  },
}
</script>

<style scoped>
.v-data-table >>> tr:hover {
  background: none !important;
}
.v-data-table {
  cursor: auto;
}
.filter-btn {
  cursor: pointer;
  align-self: end;
  position: relative;
  top: 4px;
}

/* * {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
} */
.space-25 {
  height: 25px;
}
.expand-enter-active,
.expand-leave-active {
  transition-property: opacity, height;
}
.expand-enter,
.expand-leave-to {
  opacity: 0;
}
.nolink {
  /* pointer-events: none; */
  text-decoration: none;
}
</style>
