<template>
  <ActionDialog
    v-bind="$attrs"
    v-on="$listeners"
    label="Вы действительно хотите отменить отправку проекта в Минздрав и вернуть его в работу ?"
    labelBtnClose="ОТМЕНА"
    labelBtnAction="ВЕРНУТЬ"
  />
</template>

<script>
import ActionDialog from '@/components/dialogs/ActionDialog.vue'
export default {
  name: 'MedicinePAgeUnSendDialog',
  components: { ActionDialog },
}
</script>
