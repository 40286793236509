<template>
  <div id="report-user-assigments">
    <MultiFilter
      ref="multiFilter"
      :filterActiveGroup="active"
      :filtersData="FILTER_USERS_ASSIGNED"
      @apply="filterApply"
    />

    <v-row class="my-1">
      <v-col class="pt-1">
        <v-card elevation="0">
          <v-data-table
            dense
            :items="dataReports"
            :headers="headers"
            :search="search"
            :no-data-text="
              isNotAllowedQuarter
                ? 'Отчёт за весь период не предоставляется'
                : 'Нет назначенных работ за этот квартал'
            "
            no-results-text="Ничего не нашлось"
            hide-default-footer
            disable-pagination
            :custom-filter="customFilterField"
            :loading="!docReady"
            @pagination="pagination"
          >
            <template v-slot:top>
              <FilterAlert :isFiltered="isFiltered" />
            </template>

            <!-- Строки -->
            <template #[`item.index`]="{ index }">
              <span class="cursor-pointer">{{ pageStart + index + 1 }}.</span>
            </template>
            <template
              v-for="valType in expTypes"
              v-slot:[`item.`+valType.name]="{ value }"
            >
              {{ value || 0 }}
            </template>
            <template v-slot:[`item.user`]="{ item }">
              <div class="text--primary">
                {{ item.user.fio | shortFIO }}
              </div>
              <div class="text--secondary caption">
                {{ item.user.position || EMPTY_POSITION_TXT }}
              </div>
            </template>
            <template slot="body.append">
              <tr>
                <td></td>
                <td class="font-weight-bold">Всего назначено:</td>
                <td
                  class="text-xs-right font-weight-bold"
                  v-for="(valType, index) in expTypes"
                  :key="index"
                >
                  <v-layout justify-center>
                    {{ getTotals[valType.name] || '0' }}
                  </v-layout>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MultiFilter from '@/components/filters/MultiFilter.vue'
import { FILTER_USERS_ASSIGNED } from '@/store/const/userFilters'
import FilterAlert from '@/components/filters/components/FilterAlert.vue'
import { customSearchField } from '@/lib/customSearch'
import Quarter from '@/lib/quarter'

const EMPTY_POSITION_TXT = 'Должность не определена'

export default {
  name: 'UserAssigments',
  components: {
    MultiFilter,
    FilterAlert,
  },
  data: () => ({
    docReady: false,
    headers: [],
    quarter: new Quarter(),
    totals: {},
    FILTER_USERS_ASSIGNED,
    filters: [],
    active: '',
    EMPTY_POSITION_TXT,
    isNotAllowedQuarter: false,
    pageStart: 0,
  }),
  props: {
    expTypes: {
      type: Array,
      required: true,
      default: () => [],
    },
    preparedQuarters: {
      type: Array,
      required: true,
      default: () => [],
    },
    selectedQuarter: {
      type: String,
      required: true,
      default: 'all',
    },
    filterActiveGroup: {
      type: String,
      required: false,
      default: 'group1',
    },
    search: {
      type: String,
      required: true,
      default: '',
    },
    constAll: {
      type: String,
      required: false,
      default: 'all',
    },
    activePage: {
      type: String,
      required: true,
      default: '',
    },
  },

  created() {
    this.headers = this.makeHeaders()

    this.getData(this.selectedQuarter)

    this.unsubscribe = this.$store.subscribe((mutation /*, state*/) => {
      if (mutation.type === 'UPDATE_SETUP') {
        this.getData(this.selectedQuarter)
      }
    })

    this.active = this.filterActiveGroup
  },

  watch: {
    selectedQuarter(val) {
      this.onChangeQuarter(val)
    },
    filterActiveGroup(val) {
      this.active = val
    },

    activePage(val) {
      if (val === this.$options.name) {
        this.getData(this.selectedQuarter)
      }
    },
  },

  beforeDestroy() {
    this.unsubscribe()
  },

  methods: {
    ...mapActions(['GET_REPORTS']),

    async getData(val = null) {
      // TODO Заглушка, если выбран весь год - пока нет на бэке
      this.isNotAllowedQuarter = val === this.constAll || val === null

      if (this.isNotAllowedQuarter) {
        this.docReady = true
        return
      }

      this.docReady = false
      const requestParams = this.quarter.getRequestParams(val)

      await this.GET_REPORTS(requestParams)
      this.docReady = true
    },

    makeHeaders() {
      const head = []

      head.push({
        text: '№',
        value: 'index',
        width: '5px',
        align: 'center',
        sortable: false,
        cellClass: 'text--disabled pl-2 pr-2',
        class: 'pl-2 pr-2',
      })

      head.push({
        text: 'Пользователь',
        value: 'user',
        align: 'start',
      })
      this.expTypes.forEach(item => {
        head.push({
          text: item.title,
          value: item.name,
          align: 'center',
          width: '12%',
        })
      })

      return head
    },

    onChangeQuarter(val) {
      if (this.activePage === this.$options.name) {
        this.getData(val)
      }
    },

    filterApply(val) {
      this.filters = val
      // this.$set(this, 'filters', val)
    },

    transformReports(data) {
      if (!data || !data?.length) return []

      let res = data.map(el => {
        const { fio, position, ...other } = el
        const user = { fio, position }

        return { user, ...other }
      })

      return res
    },

    customFilterField(value, search) {
      return customSearchField(value, search, {
        field: 'position',
        value: EMPTY_POSITION_TXT,
      })
    },
    pagination({ pageStart }) {
      this.pageStart = pageStart
    },
  },

  computed: {
    ...mapGetters(['usersReports', 'usersList', 'filterWidget']),

    dataReports() {
      // let res = [...this.usersReports]
      if (this.isNotAllowedQuarter) return []

      let res = [...this.transformReports(this.usersReports)]
      let records = []
      // eslint-disable-next-line no-prototype-builtins
      if (this.filters && this.filters.hasOwnProperty('assigned')) {
        let keys = this.filters.assigned || []
        // TODO Зауниверсалить фильтры !
        if (keys.includes('is_hide_not_assigned')) {
          let arr = []
          res.forEach(el => {
            const acc = this.expTypes.reduce(
              (sum, current) => sum + el[current.name],
              0
            )

            if (acc) arr.push(el)
          })

          res = [...arr]
        }
      }

      // eslint-disable-next-line no-prototype-builtins
      if (this.filters && this.filters.hasOwnProperty('permissions')) {
        records = this.filters.permissions || []

        if (records.length && this.usersList && this.usersList.length) {
          res = res.filter(el => {
            const user = this.usersList.find(user => +user.id === el.user_id)
            if (user) {
              const col =
                records.filter(selector => user[selector] === true) || []

              return col.length
            } else return false
          })
        }
      }

      // eslint-disable-next-line no-prototype-builtins
      if (this.filters && this.filters.hasOwnProperty('worktypes')) {
        records = this.filters.worktypes || []

        if (records.length) {
          res = res.filter(el => {
            // selected.includes(el.
            return records.filter(sel => +el[sel]).length === records.length
          })
        }
      }

      return res
    },

    isFiltered() {
      return this.usersReports.length !== this.dataReports.length
    },

    getTotals() {
      let total = {}
      this.expTypes.forEach(item => {
        let acc = 0
        let type = item.name
        this.dataReports.forEach(el => {
          acc += +el[type]
        })
        total[type] = acc
      })

      return total
    },
  },
}
</script>

<style scoped>
.truncate {
  max-width: 50vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
