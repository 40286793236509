<template>
  <v-container v-if="docReady">
    <v-card outlined class="my-5">
      <v-expansion-panels flat>
        <v-expansion-panel>
          <v-expansion-panel-header
            class="text-button text--secondary cursor-help"
            ><span
              ><v-icon left small color="secondary"
                >mdi-help-circle-outline</v-icon
              >Сроки {{ !isManager ? '(только для чтения)' : '' }}</span
            ></v-expansion-panel-header
          >
          <v-expansion-panel-content
            class="text-justify text-body-2 text--secondary"
          >
            <v-form><DeadlinesDates @change="changeDeadlines" /></v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>

    <v-card v-if="isManager" outlined class="my-5">
      <v-expansion-panels multiple v-model="medPanel" flat>
        <v-expansion-panel>
          <v-expansion-panel-header
            class="text-button text--secondary cursor-help"
            ><span
              ><v-icon left small color="secondary"
                >mdi-help-circle-outline</v-icon
              >Исполнители</span
            ></v-expansion-panel-header
          >
          <v-expansion-panel-content
            class="text-justify text-body-2 text--secondary"
          >
            <v-form>
              <MedSetUsers2
                :tasks="medicine.tasks"
                :userSignerId="medicine.user_signer_id"
                :plpId="medicine.id || null"
                class="pa-0"
                @change="settings => changeItem2(settings)"
              />
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import MedSetUsers2 from '@/components/MedSetUsers2'
import DateForPicker from '@/lib/datetime'
import DeadlinesDates from '../deadlines/DeadlinesDates.vue'

export default {
  name: 'MedicineSettingsView',
  components: { MedSetUsers2, DeadlinesDates },
  data: () => ({
    docReady: false,
    timeDeadline: null,
    timeDeadlineMZ: null,
    minDate: new DateForPicker().toISO8601date,
    // medicine: null,
    changes: {
      tasks: [],
      deadLine: null,
      deadLineMZ: null,
    },
    docId: null,
    hasError: false,
    medPanel: [0],
  }),
  props: {
    medicine: {
      type: Object,
      required: true,
      default: () => ({
        name: 'DEFAULT',
        tasks: [],
      }),
    },
  },

  watch: {
    hasError(val) {
      this.$emit('onHasError', val)
    },
  },

  computed: {
    ...mapGetters(['isManager', 'storedMyTasks']),
  },

  methods: {
    changeItem2(settings) {
      this.hasError = false
      this.changes.tasks = []

      for (const key in settings) {
        settings[key].data.forEach((item, index) => {
          if (item.error) {
            this.hasError = true
            this.changes.tasks = []
          }
          if (item.change) {
            if (item.sectionName !== 'signer') {
              this.changes.tasks.push({
                userId: item.item.user_id,
                //taskId: item.item.task_id, // не нужно
                section: index,
                expertise_type_id: item.sectionTypeId,
              })
            } else {
              this.changes.signerId = item.item.user_id
            }
          }
        })
      }

      this.$emit('onChange', this.changes)
    },

    changeDeadlines(value) {
      this.$emit('onChangeDts', value)
    },
  },
  mounted() {
    this.selSigner = this.storedPlpSignerId
    this.docReady = true
  },
}
</script>
