export const DEFAULT_PASSWORD_MIN_LENGTH = 8
export const DEFAULT_PASSWORD_MAX_LENGTH = 16

export const DEFAULT_EMPTY_LF_TXT = ''
export const DEFAULT_EMPTY_ATH_TXT = 'АТХ не указан'
export const DEFAULT_EMPTY_POSITION_TXT = 'не назначено'
export const DEFAULT_NO_POSITION_TXT = 'нет назначения'
export const DEFAULT_NOT_SET_TXT = 'не установлено'

export const THREHOLD_INNER_DAYS_INFO = 14
export const THREHOLD_INNER_DAYS_WARNING = 7
export const THREHOLD_MZ_DAYS_INFO = 14
export const THREHOLD_MZ_WARNING = 7
export const COLOR_THREHOLD_INFO = 'green lighten-2'
export const COLOR_THREHOLD_WARNING = 'orange lighten-3'
export const COLOR_THREHOLD_OVERDUE = 'red lighten-3'
export const COLOR_THREHOLD_DONE = 'blue lighten-3'

export const DEFAULT_WORK_DISTRIBUTION = {
  completed: {
    icon: 'mdi-check-circle-outline',
    title: 'Работа выполнена',
  },
  work: {
    icon: 'mdi-update',
    title: 'В работе',
  },
  analogs_completed: {
    icon: 'mdi-clock-check-outline',
    title: 'Закончены аналоги',
  },
  undef: {
    icon: 'mdi-coffee-outline',
    title: 'Работа не начата',
  },
  na: {
    icon: 'mdi-circle-outline',
    title: 'Исполнитель не назначен',
  },
  unset: {
    icon: 'mdi-help-circle-outline',
    title: 'Работа ещё не распределялась',
  },
  headers: [
    {
      name: 'is_completed',
      icon: 'mdi-check',
      text: 'Выполнено',
    },
    {
      name: 'is_checked',
      icon: 'mdi-check-all',
      text: 'Проверено',
    },
    // {
    //   name: 'is_signed',
    //   icon: 'mdi-pencil',
    //   //mdi-pencil-circle-outline
    //   text: 'Подписано',
    // },
    {
      name: 'was_sent_to_mz',
      icon: 'mdi-cube-send',
      text: 'Отправлено в минздрав',
      rule: element =>
        element['is_completed'] &&
        element['is_checked'] &&
        element['user_signer_id'] &&
        !element['was_sent_to_mz'],
    },
  ],
}
