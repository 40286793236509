<template>
  <div id="user-filter-permissions" class="mt-5">
    <transition-expand>
      <div v-show="isShowFilterPanel">
        <v-card flat>
          <v-card-title v-if="title" class="py-2">
            <div class="text-left">
              <v-icon dense left>mdi-filter-outline</v-icon>
              <span class="title subtitle-1">
                {{ mainTitle }}
              </span>
            </div>
          </v-card-title>
          <v-card-text
            v-for="(filter, index) in filterItems"
            :key="filter.ref"
            :class="index === filterItems.length - 1 ? 'pb-0' : ''"
          >
            <FilterView
              :ref="filter.ref"
              :filterType="filter.type"
              :title="filter.title"
              :titleClass="filter.titleClass || ''"
              @input="val => changeFilter(val, filter.ref)"
              :dataItems="filter.data"
              :defaultState="filter.default"
            />
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-col class="text-right py-0">
              <v-btn
                outlined
                class="ma-2"
                color="blue darken-1"
                text
                @click="filterApply"
              >
                {{ btnLabelApply }}
              </v-btn>
              <!-- eslint-disable-next-line -->
              <v-btn outlined class="ma-2" text @click="filterClose">
                {{ btnLabelClose }}
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </div>
    </transition-expand>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FilterView from './components/FilterView.vue'
import TransitionExpand from '@/components/transition/TransitionExpand.vue'

export default {
  name: 'MultiFilter',
  components: { FilterView, TransitionExpand },
  props: {
    title: {
      type: String,
      required: false,
      default: 'TITLE',
    },
    btnLabelApply: {
      type: String,
      required: false,
      default: 'Применить',
    },
    btnLabelClose: {
      type: String,
      required: false,
      default: 'Закрыть',
    },
    filtersData: {
      type: Object,
      required: true,
      default: () => {},
    },
    filterActiveGroup: {
      type: String,
      required: false,
      default: 'group1',
    },
  },

  data: () => ({
    mainTitle: '',
    filters: [],
    filterItems: [],
    selected: {},
    local: {},
    isShowFilterPanel: false,
  }),

  computed: {
    ...mapGetters(['filterWidget']),
  },

  created() {
    const _this = this
    this.unsubscribe = this.$store.subscribe((mutation /*, state*/) => {
      if (mutation.type === 'filter_remove') {
        // eslint-disable-next-line no-prototype-builtins
        if (_this.filterWidget.hasOwnProperty(mutation.payload)) {
          if (_this.filterWidget[mutation.payload].remove) {
            _this.removeFilter()
            _this.resetFilter(_this.filterActiveGroup)
          }
        }
      }
      if (mutation.type === 'show_panel' || mutation.type === 'hide_panel') {
        _this.isShowFilterPanel = _this.filterWidget[_this.filterActiveGroup]
          ? _this.filterWidget[_this.filterActiveGroup].panel
          : false
      }
    })

    this.isShowFilterPanel = this.filterWidget[this.filterActiveGroup]
      ? this.filterWidget[this.filterActiveGroup]?.panel
      : false
  },

  beforeDestroy() {
    this.unsubscribe()
  },

  mounted() {
    if (!this.filtersData && this.filtersData.filters) return

    this.mainTitle = this.filtersData.title || this.title

    const name = this.filtersData.name || Math.random().toString(36).slice(2)

    const filterValues = Object.values(this.filtersData.filters)
    this.registerFilter(name)
    const arr = []
    const _this = this

    filterValues.forEach(record => {
      const key = Object.keys(record)
      const element = record[key[0]]

      const filter = {}
      filter.ref = key[0]
      filter.title = element.title || ''
      filter.titleClass = element.titleClass || ''
      filter.type = element.type

      switch (element.data.type) {
        case 'custom':
          filter.data = { [element.data.value]: element.data.title } || {}
          break

        case 'variable':
          filter.data = element.data.value || {}
          break

        case 'store':
          {
            // todo добавить проверки на корректость вх данных
            let items = _this.$store.getters[element.data.value] || []
            const fields = element.data.fields
            filter.data = this.convertArrayToObject(
              items,
              fields.key,
              fields.val
            )
          }
          break

        default:
          filter.data = {}
          break
      }

      arr.push(filter)
    })

    this.filterItems = [...arr]

    if (this.filterItems.length) this.setAvaiabledFilter(name)

    // Загрузка дефолтных значений из локал стора
    if (this.filtersData.localStoragePath) {
      const filters =
        JSON.parse(localStorage.getItem(this.filtersData.localStoragePath)) ||
        {}
      const keys = Object.keys(filters)

      let loaded = false
      keys.forEach(key => {
        const index = _this.filterItems.findIndex(elem => elem.ref === key)

        if (index !== -1) {
          const filterItem = _this.filterItems[index]
          const data = Object.keys(filterItem.data)
          let val = this.getFilterStructure(filters[key], data)

          val = val || []
          if (val.length && !loaded) {
            loaded = true
          }
          filterItem.default = [...val]
          this.selected[key] = [...val]
        }
      })

      loaded && this.$emit('isloaded', this.filterActiveGroup)
      loaded && this.$emit('apply', { ...filters })
      loaded && this.isFilterExist(this.filterActiveGroup)
    }
  },

  methods: {
    ...mapActions([
      'showFilterPanel',
      'hideFilterPanel',
      'isFilterExist',
      'isFilterNotExist',
      'doRemoveFilter',
      'resetFilter',
      'registerFilter',
      'setAvaiabledFilter',
    ]),

    filterApply() {
      // Проверить выбранны ли какие-либо фильтры (они все на 2ом уровне)
      let filters = {}

      if (this.isEmptySelected(this.selected)) {
        this.isFilterExist(this.filterActiveGroup)
        filters = this.selected
      } else {
        this.isFilterNotExist(this.filterActiveGroup)
      }

      if (this.filtersData.localStoragePath) {
        try {
          localStorage.setItem(
            this.filtersData.localStoragePath,
            JSON.stringify({ ...filters })
          )
        } catch {
          localStorage.removeItem(this.filtersData.localStoragePath)
        }
      }
      this.$emit('apply', { ...filters })
    },

    removeFilter() {
      if (this.filtersData.localStoragePath) {
        localStorage.removeItem(this.filtersData.localStoragePath)
      }
      this.filterItems.forEach(el => (el.default = []))
      this.selected = []
      this.$emit('apply', {})
      this.$forceUpdate()
    },

    filterClose() {
      this.hideFilterPanel(this.filterActiveGroup)
    },

    isFilterLoaded(val) {
      this.isFilterExist(this.filterActiveGroup)
      this.filters = val
    },

    changeFilter(val, prop) {
      if (prop) {
        this.selected[prop] = val
      }
    },

    convertArrayToObject(array, key, val) {
      const initialValue = {}
      return array.reduce((obj, item) => {
        return {
          ...obj,
          [item[key]]: item[val],
        }
      }, initialValue)
    },

    getFilterStructure(data, types = []) {
      let res = []
      if (data && types.length) {
        if (Array.isArray(data)) {
          res = data.map(el => {
            return types.includes(el) && el
          })
        }
      }

      return res
    },

    isEmptySelected(obj) {
      if (this.isEmptyObject(obj)) return true
      const keys = Object.keys(obj)
      let res = false
      keys.forEach(
        key => (res = res || (Array.isArray(obj[key]) && obj[key].length))
      )

      return res
    },

    isEmptyObject(obj) {
      let res =
        obj &&
        Object.keys(obj).length === 0 &&
        Object.getPrototypeOf(obj) === Object.prototype
      return res
    },
  },
}
</script>
