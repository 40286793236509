import { render, staticRenderFns } from "./WorkDistributionForm.vue?vue&type=template&id=5fd20618&scoped=true&"
import script from "./WorkDistributionForm.vue?vue&type=script&lang=js&"
export * from "./WorkDistributionForm.vue?vue&type=script&lang=js&"
import style0 from "./WorkDistributionForm.vue?vue&type=style&index=0&id=5fd20618&scoped=true&lang=css&"
import style1 from "./WorkDistributionForm.vue?vue&type=style&index=1&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fd20618",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpeedDial } from 'vuetify/lib/components/VSpeedDial';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBadge,VBtn,VDataTable,VIcon,VSpeedDial,VTooltip})
