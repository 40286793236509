<template>
  <div id="filter-widget">
    <v-tooltip bottom open-delay="600" close-delay="300">
      <template v-slot:activator="{ on, attrs }">
        <span
          :class="getClass(isExist)"
          @click="filterClick"
          v-bind="attrs"
          v-on="on"
          >Фильтр</span
        >
      </template>
      {{ getTextTooltip(this.panel) }}
    </v-tooltip>
    <v-btn icon class="ml-2" :color="getColor(isExist)">
      <v-tooltip v-if="isExist" bottom open-delay="600" close-delay="300">
        <template v-slot:activator="{ on, attrs }">
          <v-icon @click="removeFilter" v-bind="attrs" v-on="on">
            mdi-filter-remove-outline
          </v-icon>
        </template>
        Очистить фильтр
      </v-tooltip>
      <v-icon v-else @click="filterClick">mdi-filter-outline</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'FilterWidget',
  computed: {
    ...mapGetters(['filterWidget']),
  },
  props: {
    active: {
      type: String,
      required: false,
      default: 'group1',
    },
  },

  data: () => ({
    isExist: false,
    panel: false,
    avaiable: false,
  }),

  created() {
    const _this = this

    this.unsubscribe = this.$store.subscribe(mutation => {
      if (
        mutation.type === 'filter_exist' ||
        mutation.type === 'filter_not_exist'
      ) {
        _this.isExist = _this.filterWidget[_this.active]
          ? _this.filterWidget[_this.active]?.exist
          : false
      }
      if (mutation.type === 'filter_avaiable') {
        _this.avaiable = _this.filterWidget[_this.active]
          ? _this.filterWidget[_this.active]?.avaiable
          : false
      }
    })
  },

  mounted() {
    this.isExist = this.filterWidget[this.active]?.exist || false
    this.panel = this.filterWidget[this.active]?.panel || false
  },

  beforeDestroy() {
    this.unsubscribe()
  },

  watch: {
    active(val) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.filterWidget.hasOwnProperty(val)) {
        this.isExist = this.filterWidget[val].exist
        this.panel = this.filterWidget[val].panel
        this.avaiable = this.filterWidget[this.active]
          ? this.filterWidget[this.active]?.avaiable
          : false
      } else {
        this.panel = false
        this.isExist = false
        this.avaiable = false
      }
    },
  },

  methods: {
    ...mapActions([
      'showFilterPanel',
      'hideFilterPanel',
      'isFilterExist',
      'isFilterNotExist',
      'doRemoveFilter',
      'registerFilter',
    ]),

    filterClick() {
      if (this.filterWidget[this.active]?.avaiable) {
        if (this.filterWidget[this.active].panel) {
          this.hideFilterPanel(this.active)
          this.panel = false
        } else {
          this.showFilterPanel(this.active)
          this.panel = true
        }
      }
    },
    getColor(val) {
      return val ? 'primary' : 'disabled'
    },

    getClass(val) {
      return val ? 'text--secondary' : 'text--disabled'
    },

    removeFilter() {
      this.hideFilterPanel(this.active)
      this.isFilterNotExist(this.active)
      this.doRemoveFilter(this.active)
      this.panel = false
      this.isExist = false
      this.$emit('remove')
    },

    getTextTooltip(val) {
      if (!this.avaiable) return 'Нет доступных фильтров'

      return val ? 'Свернуть' : 'Настроить фильтры'
    },
  },
}
</script>
