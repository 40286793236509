import PERMISSIONS from '@/store/const/userPermissions'

export const FILTER_USERS_ASSIGNED = {
  localStoragePath: 'OTZ/HTA/userAssigmentFilter',
  title: 'Выбранные фильтры:',
  name: 'UserAssigments',
  filters: [
    {
      worktypes: {
        name: 'work-types',
        title: 'Типы работ:',
        titleClass: 'my-2',
        type: 'checkbox',
        data: {
          type: 'store',
          value: 'storeExpTypes',
          fields: {
            key: 'name',
            val: 'title',
          },
        },
      },
    },
    {
      assigned: {
        name: 'assigned-works',
        title: 'Пользователи:',
        titleClass: 'my-2 py-0',
        type: 'switch',
        data: {
          type: 'custom',
          value: 'is_hide_not_assigned',
          title: 'Скрывать не распредёленных',
        },
      },
    },
  ],
}

export const FILTER_USERS_PAGE = {
  localStoragePath: 'OTZ/HTA/userPageFilter',
  title: 'Выбранные фильтры:',
  name: 'user_page',
  filters: [
    {
      permissions: {
        name: 'user-permission',
        title: 'Полномочия:',
        titleClass: 'my-2',
        type: 'checkbox',
        data: {
          type: 'variable',
          value: PERMISSIONS,
        },
      },
    },
  ],
}

export const FILTER_WORK_DISTRIBUTION_PAGE = {
  localStoragePath: 'OTZ/HTA/workDistributionPageFilter',
  title: 'Выбранные фильтры:',
  name: 'WorkDistributionForm',
  filters: [
    {
      participant: {
        name: 'workdistribution-participant',
        title: 'Вы являетесь участником проекта:',
        titleClass: 'my-2',
        type: 'switch',
        data: {
          type: 'custom',
          value: 'isParticipant',
          title: 'Скрыть не мои проекты',
        },
      },
    },
  ],
}

export const FILTER_MEDICINE_PAGE = {
  localStoragePath: 'OTZ/HTA/medicinesnPageFilter',
  title: 'Выбранные фильтры:',
  name: 'MedicinesPage',
  filters: [
    {
      participant: {
        name: 'medicinespage-participant',
        title: 'Вы являетесь участником проекта:',
        titleClass: 'my-2',
        type: 'switch',
        data: {
          type: 'custom',
          value: 'isParticipant',
          title: 'Скрыть не мои проекты',
        },
      },
    },
  ],
}
