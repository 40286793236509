<template>
  <div>
    <!-- <PageTitle title="Предложения" icon /> -->
    <MultiFilter
      ref="multiFilter"
      :filterActiveGroup="active"
      :filtersData="FILTER_MEDICINE_PAGE"
      @apply="filterApply"
    />
    <v-row class="my-1">
      <v-col class="pt-1">
        <!-- @click:row="onRowClick" -->
        <v-data-table
          :items="preparedMedicines"
          :headers="headers"
          :search="search"
          item-key="id"
          no-data-text="Нет препаратов за этот квартал"
          no-results-text="Ничего не нашлось"
          :custom-filter="customFilterField"
          :hide-default-footer="selectedQuarter !== 'all'"
          :disable-pagination="selectedQuarter !== 'all'"
          :loading="!docReady"
          :items-per-page="50"
          :footerProps="table_footer_options"
        >
          <template v-slot:top>
            <v-container fluid class="py-2 px-0">
              <v-row>
                <v-col cols="10" class="d-flex align-center">
                  <v-btn
                    v-if="isManager"
                    small
                    outlined
                    color="secondary"
                    link
                    to="/upload"
                    title="Загрузить новую заявку PLP"
                  >
                    <v-icon color="primary darken-4" small left>
                      mdi-microsoft-word
                    </v-icon>
                    Загрузить
                  </v-btn>
                </v-col>
                <v-col cols="2">
                  <FilterAlert :isFiltered="isFiltered" dense />
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:[`header.quarter`]="{ header }">
            <span>
              <span v-if="selectedQuarter === constAll">
                {{ header.text }}
              </span>
              <v-icon v-else dense :title="header.text">
                {{ header.icon }}
              </v-icon>
            </span>
          </template>

          <!-- Строки -->
          <!-- Индекс и бэйдж -->
          <template #[`item.index`]="{ index, item }">
            <v-badge
              :value="item.isParticipant && !item.was_sent_to_mz"
              bordered
              offset-x="0"
              offset-y="0"
              dot
              :color="`${item.isPartCompleted ? 'green' : 'blue'} lighten-2`"
              :title="
                item.isParticipant && !item.was_sent_to_mz
                  ? 'Вы являетесь участником экспертизы'
                  : ''
              "
            >
              <span>{{ index + 1 }}.</span>
            </v-badge>
          </template>

          <!-- Квартал -->
          <template #[`item.quarter`]="{ value, item }">
            <span>
              {{ selectedQuarter === constAll ? item.year + ' /' : '' }}
              <v-icon disabled>
                {{ quarterToIcon(value) }}
              </v-icon>
            </span>
          </template>

          <!-- Заголовки для статусов -->
          <template
            v-for="valType in DFD.headers"
            v-slot:[`header.`+valType.name]="{ header }"
          >
            <span :key="valType.name">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon dense v-bind="attrs" v-on="on">
                    {{ header.icon }}
                  </v-icon>
                </template>
                <span>{{ header.text }}</span>
              </v-tooltip>
            </span>
          </template>

          <!-- Наименование препарата -->
          <template v-slot:[`item.info`]="{ value, item, header }">
            <td
              @click="onRowClick(item)"
              :class="header.cellClass"
              :width="header.width"
            >
              <v-speed-dial
                direction="right"
                open-on-hover
                transition="slide-x-reverse-transition"
              >
                <template v-slot:activator>
                  <div class="text-truncate">
                    <span
                      :inner-html.prop="value.name | highlight(search)"
                      :title="value.name"
                      class="truncate"
                      :class="{
                        'font-weight-bold':
                          item.isParticipant && !item.was_sent_to_mz,
                      }"
                    /><br /><span class="text--secondary caption">
                      <span
                        :inner-html.prop="
                          value.ath || DEFAULT_EMPTY_ATH_TXT | highlight(search)
                        "
                      />:
                      <span
                        :title="value.lForma"
                        :inner-html.prop="
                          value.lForma ||
                          DEFAULT_EMPTY_LF_TXT | highlight(search)
                        "
                      />
                    </span>
                  </div>
                </template>
                <v-btn
                  v-if="isManager && item.was_sent_to_mz"
                  fab
                  dark
                  x-small
                  depressed
                  color="orange lighten-2"
                  title="Отмена отправки в МЗ"
                  @click.stop.prevent="openForUnSend(item)"
                >
                  <v-icon>mdi-backup-restore</v-icon>
                </v-btn>
                <v-btn
                  v-if="isManager && item.canSendToMZ"
                  fab
                  dark
                  x-small
                  depressed
                  color="blue lighten-2"
                  title="Отправить в МЗ"
                  @click.stop.prevent="openForSend(item)"
                >
                  <v-icon>mdi-cube-send</v-icon>
                </v-btn>
                <v-btn
                  v-if="isManager || isSuperAdmin"
                  fab
                  dark
                  x-small
                  depressed
                  color="green lighten-2"
                  title="Редактировать"
                  @click.stop.prevent="openForEdit(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  v-if="isSuperAdmin"
                  fab
                  dark
                  x-small
                  depressed
                  color="red lighten-2"
                  title="Удалить"
                  @click.stop.prevent="openForDelete(item)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-speed-dial>
            </td>
          </template>

          <!-- Общие статусы -->
          <template
            v-for="valType in DFD.headers"
            v-slot:[`item.`+valType.name]="{ value, item }"
          >
            <td :key="valType.name">
              <v-tooltip bottom>
                <!-- <v-tooltip bottom v-if="valType.type === 'status'"> -->
                <template v-slot:activator="{ on, attrs }">
                  <span
                    dense
                    v-bind="attrs"
                    v-on="on"
                    class="text-center cursor-help"
                  >
                    <v-icon small :color="value ? 'success' : ''" v-if="value">
                      mdi-check
                    </v-icon>
                    <span v-else-if="valType.rule && valType.rule(item)">
                      <v-icon color="blue lighten-2" dense>
                        mdi-package-variant
                      </v-icon>
                    </span>
                    <span v-else>&nbsp;-&nbsp;</span>
                  </span>
                </template>
                <span v-if="valType.rule && valType.rule(item)">
                  Готово к отправке в Минздрав
                </span>
                <span v-else>{{ value ? 'Да' : 'Нет' }}</span>
              </v-tooltip>
            </td>
          </template>

          <template v-slot:[`item.signer`]="{ value }">
            <span v-if="value">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    small
                    :color="value.id === userId ? 'success' : null"
                    v-bind="attrs"
                    v-on="on"
                    class="cursor-help"
                  >
                    <v-icon small v-text="`mdi-account-edit`" />
                  </v-chip>
                </template>
                <span v-if="value.id === userId">
                  Для данного препарата - Вы назначены подписантом.
                </span>
                <span v-else>Подписант: {{ value.fio }}</span>
              </v-tooltip>
            </span>
            <span v-else>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    v-text="`mdi-account-question-outline`"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <span>Подписант не назначен</span>
              </v-tooltip></span
            >
          </template>
          <!-- Время -->
          <template v-slot:[`item.timeDedlineInner`]="{ item }">
            <v-chip
              v-if="item.timeDedlineInnerColor"
              class="ma-2 cursor-help"
              :color="item.timeDedlineInnerColor"
              text-color="white"
            >
              {{ item.timeDedlineInner }}
            </v-chip>
            <span v-else>{{ item.timeDedlineInner }}</span>
          </template>

          <template v-slot:[`item.timeDedlineMZ`]="{ item }">
            <v-chip
              v-if="item.timeDedlineMZColor"
              class="ma-2 cursor-help"
              :color="item.timeDedlineMZColor"
              text-color="white"
            >
              {{ item.timeDedlineMZ }}
            </v-chip>
            <span v-else>{{ item.timeDedlineMZ }}</span>
          </template>

          <template v-slot:[`item.quarterId`]="{ item }">
            <span class="pl-4">{{ item.readableQuarter }}</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <WorkDistributionEditDialog
      :selectedItem="selectedItem"
      :medicine="medicine"
      :dialog="dialogEdit"
      @onClose="onCloseEditDialog"
    />

    <WorkDistributionDeleteDialog
      :name="selectedItem.name"
      :dialog="dialogDelete"
      @onClose="onCloseDeleteDialog"
      @onAction="onDeleteItem"
    />

    <MedicinePageSendDialog
      :name="selectedItem.name"
      :dialog="dialogSend"
      @onClose="onCloseSendDialog"
      @onAction="onSendItem"
    />

    <MedicinePageUnSendDialog
      :name="selectedItem.name"
      :dialog="dialogUnSend"
      @onClose="onCloseUnSendDialog"
      @onAction="onUnSendItem"
    />
  </div>
</template>

<script>
// import PageTitle from '@/components/PageTitle.vue'
import Quarter from '@/lib/quarter'
import { getParticipant } from '@/lib/helpers'
import { mapGetters, mapActions } from 'vuex'
import {
  DEFAULT_EMPTY_ATH_TXT,
  THREHOLD_INNER_DAYS_INFO,
  THREHOLD_INNER_DAYS_WARNING,
  THREHOLD_MZ_DAYS_INFO,
  THREHOLD_MZ_WARNING,
  COLOR_THREHOLD_INFO,
  COLOR_THREHOLD_WARNING,
  COLOR_THREHOLD_OVERDUE,
  COLOR_THREHOLD_DONE,
  DEFAULT_WORK_DISTRIBUTION as DFD,
  DEFAULT_NOT_SET_TXT,
} from '@/store/const/userParamsDefault'
import { customSearchField } from '@/lib/customSearch'
import DateForPicker from '@/lib/datetime'
import WorkDistributionEditDialog from '@/components/dialogs/WorkDistributionEditDialog.vue'
import WorkDistributionDeleteDialog from '@/components/dialogs/WorkDistributionDeleteDialog.vue'
import MedicinePageSendDialog from '@/components/dialogs/MedicinePageSendDialog.vue'
import MedicinePageUnSendDialog from '@/components/dialogs/MedicinePageUnSendDialog.vue'
import FilterAlert from '@/components/filters/components/FilterAlert.vue'
import MultiFilter from '@/components/filters/MultiFilter.vue'
import { FILTER_MEDICINE_PAGE } from '@/store/const/userFilters'

export default {
  name: 'MedicinesPage',
  components: {
    WorkDistributionEditDialog,
    WorkDistributionDeleteDialog,
    MedicinePageSendDialog,
    MedicinePageUnSendDialog,
    FilterAlert,
    MultiFilter,
  },
  data() {
    return {
      selectedItem: {
        name: 'DEFAULT',
        tasks: [],
      },
      medicine: {},
      dialogEdit: false,
      dialogDelete: false,
      dialogSend: false,
      dialogUnSend: false,
      docReady: false,
      quarterReady: false,
      quarter: new Quarter(),
      // search: '',
      updating: false,
      // selectedQuarter: null,
      headers: [],
      DEFAULT_EMPTY_ATH_TXT,
      DFD,
      // TODO Заглушка роли - если понадобится супер пупер админа
      isSuperAdmin: false,
      active: '',
      filters: [],
      FILTER_MEDICINE_PAGE,
      filterInUse: false,
      table_footer_options: {
        itemsPerPageOptions: [25, 50, 100, -1],
      },
    }
  },
  props: {
    expTypes: {
      type: Array,
      required: true,
      default: () => [],
    },
    preparedQuarters: {
      type: Array,
      required: true,
      default: () => [],
    },
    selectedQuarter: {
      type: String,
      required: true,
      default: '2022Q3',
    },
    filterActiveGroup: {
      type: String,
      required: false,
      default: 'group3',
    },
    search: {
      type: String,
      required: true,
      default: '',
    },
    constAll: {
      type: String,
      required: false,
      default: 'all',
    },
    loading: {
      type: Boolean,
      require: false,
      default: false,
    },
    activePage: {
      type: String,
      required: true,
      default: '',
    },
  },

  created() {
    this.active = this.filterActiveGroup

    if (this.loading && !this.quarterReady) {
      this.quarterReady = true
      this.load(this.selectedQuarter)
    }
  },

  watch: {
    selectedQuarter(val) {
      this.onChangeQuarter(val)
    },
    filterActiveGroup(val) {
      this.active = val
    },
    loading(val) {
      if (val && !this.quarterReady) {
        this.quarterReady = true
        this.load(this.selectedQuarter)
      }
    },
    activePage(val) {
      if (val === this.$options.name) {
        this.load(this.selectedQuarter)
      }
    },
  },

  computed: {
    ...mapGetters(['storedMedicines', 'userID', 'isManager', 'isCurator']),
    medicines() {
      return this.storedMedicines
    },
    userId() {
      return this.userID
    },
    preparedMedicines() {
      if (!this.docReady) return []
      const medicines = this.addQuarters(this.medicines)
      let body = []

      medicines.forEach(item => {
        let expertiseObj = {}
        this.expTypes.forEach(expType => {
          expertiseObj[expType.name] = this.parseTasks(item.tasks, expType.name)
        })
        const participant = getParticipant(
          expertiseObj,
          this.userID,
          this.expTypes
        )
        body.push({
          info: {
            name: item.name || item.mnn,
            ath: item.ath,
            lForma: item.lForma,
          },
          timeDedlineInner: item.time_deadline_inner
            ? new DateForPicker(item.time_deadline_inner).toISO8601date
            : DEFAULT_NOT_SET_TXT,
          timeDedlineInnerColor: item.was_sent_to_mz
            ? null
            : item.is_checked && item.time_deadline_inner
            ? COLOR_THREHOLD_DONE
            : this.getColorOnThresholdDays(item.time_deadline_inner, 'inner'),
          timeDedlineMZ: item.time_deadline_mz
            ? new DateForPicker(item.time_deadline_mz).toISO8601date
            : DEFAULT_NOT_SET_TXT,
          timeDedlineMZColor: item.was_sent_to_mz
            ? null // COLOR_THREHOLD_DONE
            : this.getColorOnThresholdDays(item.time_deadline_mz, 'mz'),
          isParticipant:
            participant.contains || item.signer?.id === this.userID,
          isPartCompleted: participant.completed,
          canSendToMZ:
            item['is_completed'] &&
            item['is_checked'] &&
            item['user_signer_id'] &&
            !item['was_sent_to_mz'],
          ...item,
        })
      })

      // TODO !!! )))
      if (this.filters && this.filters.participant) {
        let keys = this.filters.participant || []

        if (keys.length) {
          body = body.filter(element => {
            return keys.reduce((sum, key) => sum && element[key], true)
          })
        }
      }

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.filterInUse = medicines.length !== body.length

      return body
    },

    isFiltered() {
      return this.filterInUse
    },
  },

  methods: {
    ...mapActions(['GET_MEDICINES', 'SEND_MEDICINE_TO_MZ']),
    async load(q) {
      this.docReady = false
      try {
        this.headers = this.makeHeaders(q)
        if (q === null || q === this.constAll) {
          await this.GET_MEDICINES()
        } else {
          const requestParams = this.quarter.getRequestParams(q)
          await this.GET_MEDICINES(requestParams)
        }
      } finally {
        this.docReady = true
      }
    },

    async onChangeStatusSendMZ(id = null, was_sent_to_mz = false) {
      if (id === null) return

      await this.SEND_MEDICINE_TO_MZ({ id, was_sent_to_mz })

      this.$toast.success(
        was_sent_to_mz ? 'Отправлено в Минздрав!' : 'Возвращено из Минздрава!'
      )
    },

    onChangeQuarter(val) {
      if (this.activePage === this.$options.name) {
        this.load(val)
      } else this.headers = this.makeHeaders(val)
    },

    makeHeaders(q) {
      const head = []

      head.push({
        text: '№',
        value: 'index',
        width: '55px',
        align: 'center',
        sortable: false,
        cellClass: 'text--disabled pl-2 pr-2',
        class: 'pl-2 pr-2',
      })

      if (!this.quarter.isContainsQuarter(q) || q === this.constAll) {
        head.push({
          text: 'Квартал',
          value: 'quarter',
          year: 'year',
          icon: 'mdi-calendar-range-outline',
          width: q === this.constAll ? '85px' : '55px',
          align: 'center',
          sortable: true,
          cellClass: 'text--disabled pl-2 pr-2',
          class: 'pl-4 pr-0',
        })
      }
      head.push({
        text: 'Название',
        value: 'info',
        cellClass: 'cursor-pointer no-hide',
      })
      this.DFD.headers.forEach(item => {
        head.push({
          text: item.text,
          value: item.name,
          // align: 'start',
          // width: '5%',
          width: '50px',
          icon: item.icon,
          class: 'pl-2 pr-1',
        })
      })
      head.push({
        text: 'Подписант',
        value: 'signer',
        width: '75px',
        sortable: false,
        align: 'center',
      })
      head.push({
        text: 'Крайний срок',
        value: 'timeDedlineInner',
        width: '175px',
        align: 'center',
      })
      head.push({
        text: 'Крайний срок в МЗ',
        value: 'timeDedlineMZ',
        width: '175px',
        align: 'center',
      })

      return head
    },

    addQuarters(list) {
      return list.map(o => ({
        ...o,
        readableQuarter: this.quarter.getReadableQuarterByParams(
          o.year,
          o.quarter
        ),
        quarterId: this.quarter.getQuarterIdByParams(o.year, o.quarter),
      }))
    },

    onRowClick(item) {
      this.$router.push(`medicine-${item.id}`)
    },

    openForEdit(item) {
      this.selectedItem = { ...item }
      if (this.medicines.length) {
        this.medicine = this.medicines.find(m => m.id === this.selectedItem.id)
      }
      this.dialogEdit = true
    },

    onCloseEditDialog() {
      this.dialogEdit = false
    },

    openForDelete(item) {
      this.selectedItem = { ...item }
      this.dialogDelete = true
    },

    async onDeleteItem() {
      if (this.selectedItem?.id) {
        await this.DELETE_MEDICINE(this.selectedItem.id)
        this.$toast.success('Удалено')
      }
      this.onCloseDeleteDialog()
    },

    onCloseDeleteDialog() {
      this.dialogDelete = false
    },

    openForSend(item) {
      this.selectedItem = { ...item }
      this.dialogSend = true
    },

    async onSendItem() {
      if (this.selectedItem?.id) {
        this.onChangeStatusSendMZ(this.selectedItem.id, true)
      }
      this.onCloseSendDialog()
    },

    onCloseSendDialog() {
      this.dialogSend = false
    },

    openForUnSend(item) {
      this.selectedItem = { ...item }
      this.dialogUnSend = true
    },

    async onUnSendItem() {
      if (this.selectedItem?.id) {
        this.onChangeStatusSendMZ(this.selectedItem.id, false)
      }
      this.onCloseUnSendDialog()
    },

    onCloseUnSendDialog() {
      this.dialogUnSend = false
    },

    customFilterField(value, search) {
      return customSearchField(value, search, {
        field: 'ath',
        value: DEFAULT_EMPTY_ATH_TXT,
      })
    },

    getColorOnThresholdDays(value = null, typeDate = 'mz') {
      let color = ''
      let info = ''
      let warning = ''

      if (value === null) return false

      const days = this.getDiffDateInDays(value)

      if (isNaN(days)) return false

      if (typeDate === 'inner') {
        info = THREHOLD_INNER_DAYS_INFO
        warning = THREHOLD_INNER_DAYS_WARNING
      } else {
        info = THREHOLD_MZ_DAYS_INFO
        warning = THREHOLD_MZ_WARNING
      }

      if (days < info) {
        color = COLOR_THREHOLD_INFO
      }
      if (days < warning) {
        color = COLOR_THREHOLD_WARNING
      }
      if (days < 0) {
        color = COLOR_THREHOLD_OVERDUE
      }

      // return COLOR_THREHOLD_DONE когда соотв статусы установлены.

      return color
    },

    getDiffDateInDays(value) {
      const testDate = Date.parse(value)
      const diffInMs = testDate - new Date()
      if (isNaN(diffInMs)) return NaN

      const diffInDays = diffInMs / (1000 * 60 * 60 * 24)
      return diffInDays
    },

    parseTasks(tasks = [], filter = '') {
      let n = []
      const t = tasks.filter(m => m.expertiseType.name === filter)

      t.forEach(item => {
        n.push({
          id: item.user?.id || null,
          is_completed: item?.is_completed || false,
        })
      })

      return n
    },

    filterApply(val) {
      this.filters = val
    },

    quarterToIcon(q = null) {
      let icon = ''
      switch (q) {
        case 1:
          icon = 'mdi-roman-numeral-1'
          break
        case 2:
          icon = 'mdi-roman-numeral-2'
          break
        case 3:
          icon = 'mdi-roman-numeral-3'
          break
        case 4:
          icon = 'mdi-roman-numeral-4'
          break
        default:
          break
      }

      return icon
    },
  },
}
</script>
<style scoped>
.v-data-table >>> .no-hide {
  max-width: 400px;
  min-width: 200px;
}
@media only screen and (min-width: 1500px) {
  .v-data-table >>> table {
    table-layout: fixed;
  }
  .v-data-table >>> td {
    white-space: nowrap;
    overflow: hidden;
  }
  .v-data-table >>> .no-hide {
    white-space: normal;
    overflow: unset;
    min-width: 200px;
  }
}

.v-data-table,
.cursor-default {
  cursor: default;
}
.cursor-help {
  cursor: help;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
