<template>
  <v-container v-bind="$attrs">
    <v-row
      v-for="(expertise, index) in settings"
      :key="index"
      dense
      class="mt-2"
    >
      <v-col
        cols="12"
        lg="6"
        v-for="(data, dataIndex) in expertise.data"
        :key="dataIndex"
        class="pl-3 pr-3"
      >
        <AutocompleteChoiceUser
          v-if="renderComponent"
          auto-select-first
          v-model="data.item.user_id"
          :items="accountable_m(expertise.data[dataIndex])"
          item-value="id"
          item-text="fio"
          :label="data.label"
          @change="val => actionChange(data, val)"
          :error="data.error"
          :error-messages="data.errorText"
          :loading="!docReady"
          :disabled="!docReady"
        >
          <template #icon>
            <v-icon :color="data.error ? 'error' : 'secondary'">
              {{
                expertise.data[dataIndex].icon || 'mdi-account-circle-outline'
              }}
            </v-icon>
          </template>
          <template #status>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :color="expertise.data[dataIndex].statusColor"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ expertise.data[dataIndex].statusIcon }}
                </v-icon>
              </template>
              <span>{{ expertise.data[dataIndex].statusTitle }}</span>
            </v-tooltip>
          </template>
        </AutocompleteChoiceUser>
        <!-- :onChange="val => actionChange(data, val)" -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import exp from 'constants'
import { mapGetters, mapActions } from 'vuex'
import { shortFIO } from '../lib/strUtils'
import { DEFAULT_WORK_DISTRIBUTION as DFD } from '@/store/const/userParamsDefault'
import AutocompleteChoiceUser from '@/components/editors/AutocompleteChoiceUser'
import {
  EX_LIST,
  EX_LIST_EXPERTISE,
  EX_LIST_FOREIGN,
  EXPERTISE_ICON,
} from '@/store/const/expertise'

export default {
  name: 'MedSetUsers2',
  inheritAttrs: false,
  components: { AutocompleteChoiceUser },
  props: {
    tasks: {
      type: Array,
      required: true,
      default: () => [],
    },
    userSignerId: {
      type: Number,
      required: false,
      default: 0,
    },
    plpId: {
      type: Number,
      required: false,
      default: null,
    },
  },

  data: () => ({
    docReady: false,
    renderComponent: true,
    error: false,
    tmpSignerId: null,
    DFD,
  }),

  created() {
    this.tmpSignerId = this.userSignerId
    this.getData()
  },

  computed: {
    ...mapGetters([
      'usersList',
      'storeExpTypes',
      'storedMedicines',
      'storedMedicineSigners',
    ]),

    settings() {
      let cc = {}

      this.expertiseTypes.forEach(item => {
        const labelName = item.setup_name.split('←')
        const sectionName = item.name
        const sectionTypeId = item.id

        cc[sectionName] = { data: [] }

        for (let sectionId = 0; sectionId < item.item_cnt; sectionId++) {
          cc[sectionName].data[sectionId] = {
            label: labelName[sectionId] || labelName[0],
            item: this.getSignedUserId(sectionName)[sectionId] || {
              user_id: null,
              task_id: null,
            },
            change: false,
            sectionName: sectionName,
            icon: this.getIcon(sectionName),
            sectionTypeId: sectionTypeId,
            error: false,
          }
          const task = this.tasks?.find(task => {
            return (
              task.user_id === cc[sectionName]?.data[sectionId]?.item.user_id &&
              task.expertise_type_id === item?.id
            )
          })
          const status = this.getStatus(task)
          const color = this.getTaskColor(task)

          if (task) {
            cc[sectionName].data[sectionId].statusIcon = cc[sectionName].data[
              sectionId
            ].item.user_id
              ? status.icon
              : DFD.na.icon
            cc[sectionName].data[sectionId].statusTitle = cc[sectionName].data[
              sectionId
            ].item.user_id
              ? status.title
              : DFD.na.title
            cc[sectionName].data[sectionId].statusColor = cc[sectionName].data[
              sectionId
            ].item.user_id
              ? color
              : 'grey'
          } else {
            cc[sectionName].data[sectionId].statusIcon = DFD.unset.icon
            cc[sectionName].data[sectionId].statusTitle = DFD.unset.title
            cc[sectionName].data[sectionId].statusColor = 'grey'
          }
        }
      })
      cc.signer = { data: [] }
      cc.signer.data[0] = {
        label: 'Подписант',
        item: {
          user_id: this.userSignerId || null,
        },
        change: false,
        sectionName: 'signer',
        icon: 'mdi-pencil',
        sectionTypeId: 0,
        error: false,
      }

      return cc
    },

    expertiseTypes() {
      return this.storeExpTypes
    },
  },
  methods: {
    ...mapActions(['GET_MEDICINE_SIGNERS', 'get_users']),

    getStatus(task = null) {
      // в зависимости от этапа работы, на котором находится юзер, выбирает соответствующий значок из DFD

      if (task?.is_completed) {
        return this.DFD.completed
      } else if (task?.is_analog_completed) {
        return this.DFD.analogs_completed
      } else if (task?.is_in_work) {
        return this.DFD.work
      }
      return this.DFD.undef

      // <!-- mdi-worker -->
    },
    getTaskColor(task = null) {
      // в зависимости от этапа работы, на котором находится юзер, выбирает цвет значка
      if (task === null) return ''

      if (task.is_completed) return 'success'
      if (task.is_analog_completed) return 'purple'
      if (task.is_in_work) return 'primary'

      return ''
    },

    async getData() {
      this.docReady = false
      try {
        await Promise.all([
          this.plpId ? this.GET_MEDICINE_SIGNERS(this.plpId) : null,
          this.get_users(),
        ])
      } finally {
        this.docReady = true
        this.forceRender()
      }
    },

    getIcon(sectionName) {
      return EXPERTISE_ICON(sectionName)
    },

    accountable_m(expertise) {
      const errTextExpertise = 'Убрано разрешение на экпертизу'
      const errTextSigner = 'Конфликт интересов'

      if (expertise.sectionName === 'signer') {
        if (this.storedMedicineSigners?.length) {
          let signer = []
          let tmpSigner = null
          signer.push({ id: null, fio: 'Не назначено' })
          if (
            this.storedMedicineSigners?.findIndex(
              el => +el.id === +this.tmpSignerId
            ) === -1
          ) {
            tmpSigner = this.usersList.find(el => +el.id === +this.userSignerId)
            if (tmpSigner) {
              signer.push(this.getItemObject(tmpSigner, 'red'))
              expertise.error = true
              expertise.errorText = errTextSigner
            }
          }
          this.storedMedicineSigners?.forEach(item => {
            signer.push(this.getItemObject(item))
          })
          return signer
        } else {
          return this.usersList.reduce(
            (signer, item) => {
              if (item.is_in_department || item.id === this.userSignerId)
                signer.push(this.getItemObject(item))
              return signer
            },
            [{ id: null, fio: 'Не назначено' }]
          )
        }
      } else {
        return this.usersList.reduce(
          (list, item) => {
            if (EX_LIST.includes(expertise.sectionName)) {
              if (
                (EX_LIST_EXPERTISE.includes(expertise.sectionName) &&
                  item.can_execute_expertise) ||
                (EX_LIST_FOREIGN.includes(expertise.sectionName) &&
                  item.can_evaluate_agencies)
              ) {
                list.push(this.getItemObject(item))
              } else if (item.id === expertise.item.user_id) {
                list.push(this.getItemObject(item))
                expertise.error = true
                expertise.errorText = errTextExpertise
              }
            }
            return list
          },
          [{ id: null, fio: 'Не назначено' }]
        )
      }
    },

    forceRender() {
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    },

    actionChange(data, val) {
      const items = this.settings[data.sectionName].data

      this.error = false

      items.forEach(item => {
        item.change = true
        item.error = false
        item.errorText = ''
      })

      const e = items.filter(value => value.item.user_id === val)

      if (e.length > 1) {
        items.forEach((value, index) => {
          if (value.item.user_id != null && value.item.user_id === val) {
            this.error = true
            this.settings[data.sectionName].data[index].change = false
            this.settings[data.sectionName].data[index].error = true
            this.settings[data.sectionName].data[index].errorText =
              'Двойное назначение одного исполнителя'
          }
        })
      }

      if (data.sectionName === 'signer') {
        const signers = this.settings[data.sectionName].data
        const signer = signers.filter(value => value.item.user_id === val)
        if (signer.length === 1) {
          this.tmpSignerId = signer[0].item?.user_id ?? this.userSignerId
        }
      }

      this.$emit('change', this.settings)

      this.forceRender()
    },

    getSignedUserId(expertise) {
      return this.tasks.reduce((task, item) => {
        if (item.expertiseType.name === expertise) {
          task.push({ user_id: item.user_id, task_id: item.id })
        }
        return task
      }, [])
    },

    getItemObject(item, color = null) {
      return {
        id: item.id,
        fio: shortFIO(item.fio),
        position: item.position,
        role: {
          id: (item.role && item.role?.id) || '',
          title: (item.role && item.role?.title) || '',
        },
        color,
      }
    },
  },
}
</script>
