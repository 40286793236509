<template>
  <div id="work-distribution-form">
    <MultiFilter
      ref="multiFilter"
      :filterActiveGroup="active"
      :filtersData="FILTER_WORK_DISTRIBUTION_PAGE"
      @apply="filterApply"
    />

    <v-data-table
      :items="preparedMedicines"
      :headers="headers"
      :search="search"
      no-data-text="Нет препаратов за этот квартал"
      no-results-text="Ничего не нашлось"
      :custom-filter="customFilterField"
      :hide-default-footer="selectedQuarter !== 'all'"
      :disable-pagination="selectedQuarter !== 'all'"
      :loading="!docReady"
      :items-per-page="50"
      :footerProps="table_footer_options"
    >
      <!-- @click:row="openForEdit" -->
      <template v-slot:top>
        <FilterAlert :isFiltered="isFiltered" />
      </template>
      <template
        v-for="valType in DFD.headers"
        v-slot:[`header.`+valType.name]="{ header }"
      >
        <span :key="valType.name">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon dense v-bind="attrs" v-on="on">
                {{ header.icon }}
              </v-icon>
            </template>
            <span>{{ header.text }}</span>
          </v-tooltip>
        </span>
      </template>
      <template v-slot:[`header.quarter`]="{ header }">
        <span>
          <span v-if="selectedQuarter === constAll">
            {{ header.text }}
          </span>
          <v-icon v-else dense :title="header.text">
            {{ header.icon }}
          </v-icon>
        </span>
      </template>

      <!-- Строки -->

      <!-- Индекс и бэйдж -->
      <template #[`item.index`]="{ index, item }">
        <v-badge
          :value="item.isParticipant && !item.was_sent_to_mz"
          bordered
          offset-x="0"
          offset-y="0"
          dot
          :color="`${item.isPartCompleted ? 'green' : 'blue'} lighten-2`"
          :title="
            item.isParticipant && !item.was_sent_to_mz
              ? 'Вы являетесь участником экспертизы'
              : ''
          "
        >
          <span>{{ index + 1 }}.</span>
        </v-badge>
      </template>

      <!-- Квартал -->
      <template #[`item.quarter`]="{ value, item }">
        <span>
          {{ selectedQuarter === constAll ? item.year + ' /' : '' }}
          <v-icon disabled>
            {{ quarterToIcon(value) }}
          </v-icon>
        </span>
      </template>

      <template
        v-for="valType in columns"
        v-slot:[`item.`+valType.name]="{ value, item, header }"
      >
        <td
          :key="valType.name"
          :width="header.width"
          :class="`${header.cellClass || ''} ${getTDClass(item, valType.type)}`"
          @click="valType.type === 'name' && onRowClick(item)"
        >
          <!-- Наименование препарата -->
          <v-speed-dial
            v-if="valType.type === 'name'"
            direction="right"
            open-on-hover
            transition="slide-x-reverse-transition"
          >
            <template v-slot:activator>
              <div class="text-truncate">
                <span
                  :inner-html.prop="value.name | highlight(search)"
                  :title="value.name"
                  :class="{
                    'font-weight-bold':
                      item.isParticipant && !item.was_sent_to_mz,
                  }"
                /><br /><span class="text--secondary caption">
                  <span
                    :inner-html.prop="
                      value.ath || DEFAULT_EMPTY_ATH_TXT | highlight(search)
                    "
                  />:
                  <span
                    :title="value.lForma"
                    :inner-html.prop="
                      value.lForma || DEFAULT_EMPTY_LF_TXT | highlight(search)
                    "
                  />
                </span>
              </div>
            </template>
            <v-btn
              v-if="isManager && item.was_sent_to_mz"
              fab
              dark
              x-small
              depressed
              color="orange lighten-2"
              title="Отмена отправки в МЗ"
              @click.stop.prevent="openForUnSend(item)"
            >
              <v-icon>mdi-backup-restore</v-icon>
            </v-btn>
            <v-btn
              v-if="isManager && item.canSendToMZ"
              fab
              dark
              x-small
              depressed
              color="blue lighten-2"
              title="Отправить в МЗ"
              @click.stop.prevent="openForSend(item)"
            >
              <v-icon>mdi-cube-send</v-icon>
            </v-btn>
            <v-btn
              v-if="isManager || isSuperAdmin"
              fab
              dark
              x-small
              depressed
              color="green lighten-2"
              title="Редактировать"
              @click.stop.prevent="openForEdit(item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              v-if="isSuperAdmin"
              fab
              dark
              x-small
              depressed
              color="red lighten-2"
              title="Удалить"
              @click.stop.prevent="openForDelete(item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-speed-dial>

          <!-- Общие статусы -->
          <v-tooltip bottom v-if="valType.type === 'status'">
            <template v-slot:activator="{ on, attrs }">
              <span dense v-bind="attrs" v-on="on" class="text-center">
                <v-icon small :color="value ? 'success' : ''" v-if="value">
                  mdi-check
                </v-icon>
                <span v-else-if="valType.rule && valType.rule(item)">
                  <v-icon color="blue lighten-2" dense>
                    mdi-package-variant
                  </v-icon>
                </span>
                <span v-else>&nbsp;-&nbsp;</span>
              </span>
            </template>
            <span v-if="valType.rule && valType.rule(item)">
              Готово к отправке в Минздрав
            </span>
            <span v-else>{{ value ? 'Да' : 'Нет' }}</span>
          </v-tooltip>

          <!-- Типы работ -->
          <ol
            :key="valType.name"
            :class="value.length ? '' : 'ol-padding'"
            v-if="valType.type === 'work'"
          >
            <li
              v-if="value.length === 0"
              style="list-style: none"
              class="grey--text"
            >
              <span class="d-flex justify-space-between">
                <span class="text-truncate">
                  <em>{{ DEFAULT_NO_POSITION_TXT }}</em>
                </span>
                <span>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small color="grey" v-bind="attrs" v-on="on">
                        {{ DFD.unset.icon }}
                      </v-icon>
                    </template>
                    <span>{{ DFD.unset.title }}</span>
                  </v-tooltip>
                </span>
              </span>
            </li>
            <li v-else v-for="(user, index) in value" :key="index">
              <span v-if="user.fio" class="d-flex justify-space-between">
                <span
                  :inner-html.prop="user.fio | shortFIO | highlight(search)"
                  :title="user.fio"
                  class="text-truncate"
                />
                <span class="pl-1">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        :color="getUserColor(user)"
                        >{{ getUserAttrs(user).icon }}
                      </v-icon>
                    </template>
                    <span>{{ getUserAttrs(user).title }}</span>
                  </v-tooltip>
                </span>
              </span>
              <span
                v-else
                class="d-flex justify-space-between teal--text font-italic"
              >
                <span class="text-truncate">{{
                  DEFAULT_EMPTY_POSITION_TXT
                }}</span>
                <span class="pl-1">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small color="grey" v-bind="attrs" v-on="on">
                        {{ DFD.na.icon }}
                      </v-icon>
                    </template>
                    <span>{{ DFD.na.title }}</span>
                  </v-tooltip>
                </span>
              </span>
            </li>
          </ol>
          <!-- Signer -->
          <span v-if="valType.type === 'signer'">
            <span v-if="item.signer">
              {{ item.signer.fio | shortFIO | highlight(search) }} </span
            ><span v-else class="grey--text font-italic">{{
              DEFAULT_NO_POSITION_TXT
            }}</span>
          </span>
        </td>
      </template>
    </v-data-table>

    <WorkDistributionEditDialog
      :selectedItem="selectedItem"
      :medicine="medicine"
      :dialog="dialogEdit"
      @onClose="onCloseEditDialog"
    />

    <WorkDistributionDeleteDialog
      :name="selectedItem.name"
      :dialog="dialogDelete"
      @onClose="onCloseDeleteDialog"
      @onAction="onDeleteItem"
    />

    <MedicinePageSendDialog
      :name="selectedItem.name"
      :dialog="dialogSend"
      @onClose="onCloseSendDialog"
      @onAction="onSendItem"
    />

    <MedicinePageUnSendDialog
      :name="selectedItem.name"
      :dialog="dialogUnSend"
      @onClose="onCloseUnSendDialog"
      @onAction="onUnSendItem"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Quarter from '@/lib/quarter'
import { getParticipant } from '@/lib/helpers'
import WorkDistributionEditDialog from '@/components/dialogs/WorkDistributionEditDialog.vue'
import WorkDistributionDeleteDialog from '@/components/dialogs/WorkDistributionDeleteDialog.vue'
import MedicinePageSendDialog from '@/components/dialogs/MedicinePageSendDialog.vue'
import MedicinePageUnSendDialog from '@/components/dialogs/MedicinePageUnSendDialog.vue'
import {
  DEFAULT_WORK_DISTRIBUTION as DFD,
  DEFAULT_EMPTY_ATH_TXT,
  DEFAULT_EMPTY_LF_TXT,
  DEFAULT_EMPTY_POSITION_TXT,
  DEFAULT_NO_POSITION_TXT,
} from '@/store/const/userParamsDefault'
import { customSearchField } from '@/lib/customSearch'
import FilterAlert from '@/components/filters/components/FilterAlert.vue'
import MultiFilter from '@/components/filters/MultiFilter.vue'
import { FILTER_WORK_DISTRIBUTION_PAGE } from '@/store/const/userFilters'

export default {
  name: 'WorkDistributionForm',
  components: {
    WorkDistributionEditDialog,
    WorkDistributionDeleteDialog,
    MedicinePageSendDialog,
    MedicinePageUnSendDialog,
    FilterAlert,
    MultiFilter,
  },
  data: () => ({
    selectedItem: {
      name: 'DEFAULT',
      tasks: [],
    },
    refreshKey: false,
    dialogEdit: false,
    dialogDelete: false,
    dialogSend: false,
    dialogUnSend: false,
    docReady: false,
    quarter: new Quarter(),
    medicine: {},
    quarters: [],
    headers: [],
    selectedQuarterSelf: '',
    columns: [],
    DFD,
    DEFAULT_EMPTY_ATH_TXT,
    DEFAULT_EMPTY_LF_TXT,
    DEFAULT_EMPTY_POSITION_TXT,
    DEFAULT_NO_POSITION_TXT,
    // TODO Заглушка роли - если понадобится супер пупер админа
    isSuperAdmin: false,
    active: '',
    filters: [],
    FILTER_WORK_DISTRIBUTION_PAGE,
    filterInUse: false,
    table_footer_options: {
      itemsPerPageOptions: [25, 50, 100, -1],
    },
  }),
  props: {
    expTypes: {
      type: Array,
      required: true,
      default: () => [],
    },
    preparedQuarters: {
      type: Array,
      required: true,
      default: () => [],
    },
    selectedQuarter: {
      type: String,
      required: true,
      default: '2022Q3',
    },
    filterActiveGroup: {
      type: String,
      required: false,
      default: 'group2',
    },
    search: {
      type: String,
      required: true,
      default: '',
    },
    constAll: {
      type: String,
      required: false,
      default: 'all',
    },
    activePage: {
      type: String,
      required: true,
      default: '',
    },
  },

  created() {
    this.selectedQuarterSelf = this.selectedQuarter

    this.active = this.filterActiveGroup
    this.load(this.selectedQuarter)
  },

  watch: {
    selectedQuarter(val) {
      this.onChangeQuarter(val)
    },
    filterActiveGroup(val) {
      this.active = val
    },
    activePage(val) {
      if (val === this.$options.name) {
        this.load(this.selectedQuarter)
      }
    },
  },

  methods: {
    ...mapActions(['GET_MEDICINES', 'DELETE_MEDICINE', 'SEND_MEDICINE_TO_MZ']),

    async load(q = null) {
      this.docReady = false

      try {
        this.headers = this.makeHeaders(q)
        if (q === null || q === this.constAll) {
          await this.GET_MEDICINES()
        } else {
          const requestParams = this.quarter.getRequestParams(q)
          await this.GET_MEDICINES(requestParams)
        }

        this.columns = this.makeColumnsArray()
      } finally {
        this.docReady = true
      }
    },

    onChangeQuarter(val) {
      if (this.activePage === this.$options.name) {
        this.selectedQuarterSelf = val
        this.load(val)
      } else this.headers = this.makeHeaders(val)
    },

    onRowClick(item) {
      this.$router.push(`medicine-${item.id}`)
    },

    openForEdit(item) {
      this.selectedItem = { ...item }
      if (this.getMedicines.length) {
        this.medicine = this.getMedicines.find(
          m => m.id === this.selectedItem.id
        )
      }
      // this.changes.docId = this.selectedItem.id || null
      this.dialogEdit = true
    },

    onCloseEditDialog() {
      this.dialogEdit = false
    },

    openForDelete(item) {
      this.selectedItem = { ...item }
      this.dialogDelete = true
    },

    async onDeleteItem() {
      if (this.selectedItem?.id) {
        await this.DELETE_MEDICINE(this.selectedItem.id)
        this.$toast.success('Удалено')
      }
      this.onCloseDeleteDialog()
    },

    onCloseDeleteDialog() {
      this.dialogDelete = false
    },

    openForSend(item) {
      this.selectedItem = { ...item }
      this.dialogSend = true
    },

    async onSendItem() {
      if (this.selectedItem?.id) {
        this.onChangeStatusSendMZ(this.selectedItem.id, true)
      }
      this.onCloseSendDialog()
    },

    onCloseSendDialog() {
      this.dialogSend = false
    },

    openForUnSend(item) {
      this.selectedItem = { ...item }
      this.dialogUnSend = true
    },

    async onUnSendItem() {
      if (this.selectedItem?.id) {
        this.onChangeStatusSendMZ(this.selectedItem.id, false)
      }
      this.onCloseUnSendDialog()
    },

    onCloseUnSendDialog() {
      this.dialogUnSend = false
    },

    async onChangeStatusSendMZ(id = null, was_sent_to_mz = false) {
      if (id === null) return

      await this.SEND_MEDICINE_TO_MZ({ id, was_sent_to_mz })

      this.$toast.success(
        was_sent_to_mz ? 'Отправлено в Минздрав!' : 'Возвращено из Минздрава!'
      )
    },

    parseTasks(tasks = [], filter = '') {
      let n = []
      const t = tasks.filter(m => m.expertiseType.name === filter)

      t.forEach(item => {
        n.push({
          fio: item.user?.fio || '',
          id: item.user?.id || null,
          is_in_work: item?.is_in_work || false,
          is_completed: item?.is_completed || false,
          is_analog_completed: item?.is_analog_completed || false,
        })
      })

      return n
    },

    makeHeaders(q) {
      const head = []

      head.push({
        text: '№',
        value: 'index',
        width: '55px',
        align: 'center',
        sortable: false,
        class: 'pl-2 pr-2 fixed-column-index',
        cellClass: 'text--disabled cursor-default fixed-column-index',
      })
      if (!this.quarter.isContainsQuarter(q) || q === this.constAll) {
        head.push({
          text: 'Квартал',
          value: 'quarter',
          year: 'year',
          icon: 'mdi-calendar-range-outline',
          width: q === this.constAll ? '85px' : '55px',
          align: 'center',
          sortable: true,
          cellClass: 'text--disabled pl-2 pr-2',
          class: 'pl-4 pr-0',
        })
      }
      head.push({
        text: 'Название',
        value: 'info',
        align: 'start',
        width: '350px',
        class: 'fixed-column-name',
        cellClass: 'no-hide fixed-column-name',
      })
      this.DFD.headers.forEach(item => {
        head.push({
          text: item.text,
          value: item.name,
          width: '40px',
          sortable: false,
          icon: item.icon,
          class: 'pl-2 pr-1',
          cellClass: 'text-no-wrap',
        })
      })
      this.expTypes.forEach(item => {
        head.push({
          text: item.title,
          value: item.name,
          align: 'start',
          width: '175px',
          sortable: false,
          class: 'pl-4 pr-1',
          cellClass: 'text-no-wrap',
        })
      })
      head.push({
        text: 'Подписант',
        value: 'signer',
        align: 'start',
        width: '155px',
        sortable: false,
        cellClass: 'text-no-wrap',
        class: 'pl-4 pr-1',
      })

      return head
    },

    makeColumnsArray() {
      return [
        { name: 'info', type: 'name' },
        ...this.DFD.headers.map(el => {
          return { name: el.name, type: 'status', rule: el?.rule }
        }),
        ...this.expTypes.map(el => {
          return { name: el.name, type: 'work' }
        }),
        { name: 'signer', type: 'signer' },
      ]
    },

    addQuarters(list) {
      return list.map(o => ({
        ...o,
        readableQuarter: this.quarter.getReadableQuarterByParams(
          o.year,
          o.quarter
        ),
        quarterId: this.quarter.getQuarterIdByParams(o.year, o.quarter),
      }))
    },

    getUserAttrs(user = null) {
      // в зависимости от этапа работы, на котором находится юзер, выбирает соответствующий значок из DFD
      if (user === null) return ''

      if (this.docReady) {
        if (user.is_completed) {
          return this.DFD.completed
        } else if (user.is_analog_completed) {
          return this.DFD.analogs_completed
        } else if (user.is_in_work) {
          return this.DFD.work
        }
        return this.DFD.undef
      } else return ''
      // <!-- mdi-worker -->
    },

    getUserColor(user = null) {
      // в зависимости от этапа работы, на котором находится юзер, выбирает цвет значка
      if (user === null) return ''

      if (user.is_completed) return 'success'
      if (user.is_analog_completed) return 'purple'
      if (user.is_in_work) return 'primary'

      return ''
    },

    getTableAlign(item) {
      let res = false
      this.expTypes.every(key => {
        let el = item[key.name]
        if (el && Array.isArray(el) && el.length > 1) {
          res = true
          return
        }
      })

      return res
    },

    getTDClass(item, type) {
      let cssClass = 'py-2'
      if (type === 'work' || type === 'signer') {
        cssClass += ` px-2 cursor-default ${
          this.getTableAlign(item) ? 'v-table-top' : ''
        }`
      }
      if (type === 'name') {
        cssClass += ' cursor-pointer'
      }

      if (type === 'status') {
        cssClass += ' cursor-default'
      }

      if (type === 'signer') {
        cssClass += ' pl-4 text-left'
      }

      return cssClass
    },

    customFilterField(value, search) {
      return customSearchField(value, search, {
        field: 'ath',
        value: DEFAULT_EMPTY_ATH_TXT,
      })
    },

    filterApply(val) {
      this.filters = val
    },

    quarterToIcon(q = null) {
      let icon = ''
      switch (q) {
        case 1:
          icon = 'mdi-roman-numeral-1'
          break
        case 2:
          icon = 'mdi-roman-numeral-2'
          break
        case 3:
          icon = 'mdi-roman-numeral-3'
          break
        case 4:
          icon = 'mdi-roman-numeral-4'
          break
        default:
          break
      }

      return icon
    },
  },

  computed: {
    ...mapGetters(['storedMedicines', 'isManager', 'isCurator', 'userID']),

    getMedicines() {
      return this.storedMedicines
    },

    preparedMedicines() {
      if (!this.docReady) return

      const medicines = this.addQuarters(this.getMedicines)
      let body = []

      medicines.forEach(item => {
        // console.log(item)
        const expertiseObj = {}
        this.expTypes.forEach(expType => {
          expertiseObj[expType.name] = this.parseTasks(item.tasks, expType.name)
        })
        const participant = getParticipant(
          expertiseObj,
          this.userID,
          this.expTypes
        )
        body.push({
          info: {
            name: item.name || item.mnn,
            ath: item.ath,
            lForma: item.lForma,
          },
          ...expertiseObj,
          ...item,
          isParticipant:
            participant.contains || item.signer?.id === this.userID,
          isPartCompleted: participant.completed,
          canSendToMZ:
            item['is_completed'] &&
            item['is_checked'] &&
            item['user_signer_id'] &&
            !item['was_sent_to_mz'],
        })
      })

      // TODO !!! )))
      if (this.filters && this.filters.participant) {
        let keys = this.filters.participant || []

        if (keys.length) {
          body = body.filter(element => {
            return keys.reduce((sum, key) => sum && element[key], true)
          })
        }
      }

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.filterInUse = medicines.length !== body.length

      return body
    },

    isFiltered() {
      return this.filterInUse
    },
  },
}
</script>

<style scoped>
.v-data-table {
  cursor: auto;
}
.v-table-top {
  vertical-align: top;
}

.v-data-table >>> .no-hide {
  max-width: 400px;
  min-width: 200px;
}

@media only screen and (min-width: 1500px) {
  .v-data-table >>> table {
    table-layout: fixed;
  }
  .v-data-table >>> td {
    white-space: nowrap;
    overflow: hidden;
  }
  .v-data-table >>> .no-hide {
    white-space: normal;
    overflow: unset;
    min-width: 200px;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}
.ol-padding {
  padding-left: 12px !important;
}
.cursor-default {
  cursor: default;
}
</style>

<style lang="sass">
.fixed-column-index
    position: sticky !important
    position: -webkit-sticky !important
    left: 0
    z-index: 4 !important
    background: white

.fixed-column-index
    position: sticky !important
    position: -webkit-sticky !important
    left: 0
    z-index: 3 !important
    background: white

.fixed-column-name
    position: sticky !important
    position: -webkit-sticky !important
    left: 55px
    z-index: 4 !important
    background: white

.fixed-column-name
    position: sticky !important
    position: -webkit-sticky !important
    left: 55px
    z-index: 3 !important
    background: white
</style>
